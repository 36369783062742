import { Pipe, PipeTransform } from '@angular/core';
import { AuthState } from 'src/app/core/services/auth.service';

@Pipe({
  name: 'canAccessImpure',
  pure: false,
})
export class canAccessImpurePipe implements PipeTransform {
  constructor(private authState: AuthState) {}
  transform(value: string): boolean {
    // Replace this with your actual access control logic
    return this.authState.canAccess(value);
  }
}
