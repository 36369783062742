import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * This directive will trigger an event on clicking outside of the element in
 * which the directive is given or when escape key is pressed
 * If input value is set true.
 */
@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Input() appClickOutside;
  @Output() appClickOutsideChange: EventEmitter<any> = new EventEmitter();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event, target) {
    if (this.appClickOutside && target === this.elementRef.nativeElement) {
      this.appClickOutsideChange.emit(false);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.appClickOutside) {
      this.appClickOutsideChange.emit(false);
    }
  }
}
