<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading"
        *ngIf="ServiceProviders || !ServiceProvider.ServiceProviderId">
        <i class="fa fa-thumbs-up heading-icon"></i>
        <span class="list-heading" *ngIf="ServiceProviders">SERVICE PROVIDERS</span>
        <span class="list-heading" *ngIf="!ServiceProviders && !ServiceProvider.ServiceProviderId">ADD SERVICE
          PROVIDER</span>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 form-heading"
        *ngIf="!ServiceProviders && ServiceProvider.ServiceProviderId">
        <i class="fa fa-thumbs-up heading-icon"></i>
        <span class="list-heading">EDIT SERVICE PROVIDER</span>
      </div>

      <div class="col-lg-8 col-md-12 col-sm-12 tabView">
        <div id="page-size" class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading pageSize"
          *ngIf="ServiceProviders">
          <ngx-atlas-select (model)="ServiceproviderPageSizeChange($event)" [list]="pageSizes" [idField]="'id'"
            [textField]="'name'" [placeholder]="'Select Size'" [allowClear]="true" [multiple]="false" [required]="true"
            [selectedValue]="size">
          </ngx-atlas-select>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="ServiceProviders && !ServiceProvider.ServiceProviderId && isSysAdmin">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Service Provider
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading view-type" *ngIf="ServiceProviders">
          <ngx-atlas-select (model)="listviewtype($event)" [list]="listviewtypes" [idField]="'id'" [textField]="'name'"
            [placeholder]="'Select List'" [allowClear]="true" [multiple]="false" [required]="true"
            [selectedValue]="viewType">
          </ngx-atlas-select>
        </div>

        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="ServiceProviders">
          <form>
            <input type="text" name="searchString" class="form-control search-radius searchBoxRadius"
              placeholder="Search" [(ngModel)]="searchString" autocomplete="off" />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn"
                  (click)="serviceprovidersSearch(searchString, size, viewType)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="ServiceProviders">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('ServiceProviderName', columnSortOrder)">
                  Service Provider Name
                  <span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SpFirstName', columnSortOrder)">
                  First Name
                  <span *ngIf="selectedColumnName == 'SpFirstName' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'SpFirstName' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SpLastName', columnSortOrder)">
                  Last Name
                  <span *ngIf="selectedColumnName == 'SpLastName' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'SpLastName' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody *ngFor="
                let ServiceProvider of ServiceProviders
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              ">
              <tr (click)="getServiceProviderById(ServiceProvider.ServiceProviderId)">
                <td>{{ ServiceProvider.ServiceProviderName }}</td>
                <td>{{ ServiceProvider.SpFirstName }}</td>
                <td>{{ ServiceProvider.SpLastName }}</td>
                <td *ngIf="ServiceProvider.IsActive">
                  <span><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top"
                      title="Active"></i></span>
                </td>
                <td *ngIf="!ServiceProvider.IsActive">
                  <span><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top"
                      title="Inactive"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls class="pagination pull-right" (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!ServiceProviders">
    <form #serviceproviderForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-3 col-md-3 col-sm-3">
            <div>
              <div class="form-group imgBoxHeight">
                <div class="imgBox" style="background: none">
                  <button class="btn-primary btn btn-xs button-text" *ngIf="serviceProviderLogoSrc && isSysAdmin"
                    id="applicationLogoReset" type="button" (click)="fileReset()">
                    <i class="fa fa-times"></i> Remove
                  </button>
                  <label class="custom-file">
                    <input type="file" #fileInput id="file" name="serviceProviderLogo" class="custom-file-input"
                      (change)="fileChange($event)" style="display: none" accept="image/*" *ngIf="isSysAdmin" />
                    <span class="custom-file-control"></span>
                    <img id="preview"
                      [src]="serviceProviderLogoSrc != null ? serviceProviderLogoSrc : serviceProviderDefaultLogo"
                      class="application-logo" [ngStyle]="{ cursor: isSysAdmin ? 'pointer' : 'default' }" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-9 col-md-9 col-sm-9">
            <div class="col-md-12 form-group">
              <label for="ServiceProviderName"><span id="red-circle">&#x25CF;</span> Service Provider Name</label>
              <div>
                <input type="text" autocomplete="invalid" class="form-control" name="ServiceProviderName"
                  placeholder="Service Provider Name" [(ngModel)]="ServiceProvider.ServiceProviderName" required
                  #ServiceProviderName="ngModel" maxlength="49" [pattern]="utilitiesService.alphanumericRegex" />
                <div *ngIf="ServiceProviderName.errors && (ServiceProviderName.dirty || ServiceProviderName.touched)"
                  class="alert alert-danger">
                  <div [hidden]="!ServiceProviderName.errors.required" class="text-danger">
                    Service Provider name is required.
                  </div>
                  <div [hidden]="!ServiceProviderName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Contact Person Information</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="SpFirstName">Contact First Name</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="SpFirstName" placeholder="First Name"
                [(ngModel)]="ServiceProvider.SpFirstName" #SpFirstName="ngModel" maxlength="49"
                [pattern]="utilitiesService.alphanumericRegex" />
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="SpLastName">Contact Last Name</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="SpLastName" placeholder="Last Name"
                [(ngModel)]="ServiceProvider.SpLastName" #SpLastName="ngModel" maxlength="49"
                [pattern]="utilitiesService.alphanumericRegex" />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="email">Contact Email</label>
            <div>
              <input type="email" autocomplete="invalid" class="form-control" name="Email" placeholder="Contact Email"
                [(ngModel)]="ServiceProvider.Email" #Email="ngModel"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" maxlength="319" />
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="MobileNumber ">Mobile No.</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="MobileNumber"
                placeholder="Mobile No. (123) 456-7890" [(ngModel)]="ServiceProvider.MobileNumber"
                #MobileNumber="ngModel" minlength="10" mask="(000) 000-0000" />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="PhoneNumber">Phone No.</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="PhoneNumber"
                placeholder="Phone No. (123) 456-7890" [(ngModel)]="ServiceProvider.PhoneNumber" #PhoneNumber="ngModel"
                minlength="10" mask="(000) 000-0000" />
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="addressLine1">Address Line 1</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="AddressLine1"
                placeholder="Address Line 1" [(ngModel)]="ServiceProvider.Address1" #AddressLineOne="ngModel"
                maxlength="249" />
              <!--pattern="^\S+$"-->
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="addressLine2">Address Line 2</label>
            <div>
              <input type="text" class="form-control" name="AddressLine2" placeholder="Address Line 2"
                [(ngModel)]="ServiceProvider.Address2" #addressLineTwo="ngModel" maxlength="249" />
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="CountryName"> Country</label>
            <div>
              <ngx-atlas-select (model)="countryChange($event)" [list]="countries" [idField]="'CountryId'"
                [textField]="'CountryName'" [placeholder]="'Select Country'" [allowClear]="true" [multiple]="false"
                [selectedValue]="ServiceProvider.Country.CountryId">
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="stateName"> State</label>
            <div>
              <ngx-atlas-select (model)="stateChange($event)" [list]="states" [idField]="'StateId'"
                [textField]="'StateName'" [placeholder]="'Select State'" [allowClear]="true" [multiple]="false"
                [disabled]="stateDisabled" [selectedValue]="ServiceProvider.State.StateId">
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="CityName"> City</label>
            <div>
              <ngx-atlas-select (model)="ServiceProvider.City.CityId = $event" [list]="cities" [idField]="'CityId'"
                [textField]="'CityName'" [placeholder]="'Select City'" [allowClear]="true" [multiple]="false"
                [disabled]="cityDisabled" [selectedValue]="ServiceProvider.City.CityId">
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 form-group">
            <label for="Pincode"> Zip Code</label>
            <div>
              <input type="text" autocomplete="invalid" class="form-control" name="Zipcode" placeholder="Zip Code"
                [(ngModel)]="ServiceProvider.ZipCode" #Zipcode="ngModel" pattern="[0-9]*$" minlength="5"
                maxlength="5" />
              <div *ngIf="Zipcode.errors && (Zipcode.dirty || Zipcode.touched)" class="alert alert-danger">
                <!--<div [hidden]="!Zipcode.errors.required" class="text-danger">
                                    Zip code is required.
                                </div>-->
                <div [hidden]="!Zipcode.errors.pattern" class="text-danger">Zip code accepts only numeric.</div>
                <div [hidden]="!Zipcode.errors.minlength || Zipcode.errors.pattern" class="text-danger">
                  Zip code should be 5 digit.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="ExpiryDate"><span id="red-circle">&#x25CF;</span> License Expiry Date</label>
            <div>
              <mat-form-field appearance="outline" style="width: 100%" id="search_crop_name">
                <input matInput [matDatepicker]="picker" id="search_crop_name" name="ExpiryDate"
                  placeholder="License Expiry Date" [(ngModel)]="ServiceProvider.SPLicenseExpiryDate"
                  #ExpiryDate="ngModel" [min]="TodayDate" [max]="9999 - 12 - 31" required [disabled]="!isSysAdmin" />
                <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="formFieldDisabled"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="LicenseKey">License Key</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping" style="color: #9a9ca1">
                  {{ ServiceProvider.SPLicenseKey ? ServiceProvider.SPLicenseKey : 'License Key' }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <div class="form-group chkbox1" style="margin-top: 33px">
              <mat-checkbox [(ngModel)]="ServiceProvider.AllowToShareUser" name="AllowToShareUser"
                [disabled]="!isSysAdmin">Allow To Share User</mat-checkbox>
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Licensing</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="InternalUserLicenseCount">Internal User License Count : {{
              ServiceProvider.InternalUserLicenseCount }}</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group">
            <label for="ExternalUserLicenseCount">External User License Count : {{
              ServiceProvider.ExternalUserLicenseCount }}</label>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">


          <div class="col-md-6 col-xs-12 form-group">
            <label for="TotalUserLicenseCount">Total User License Count : {{ ServiceProvider.TotalUserLicenseCount
              }}</label>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="AllocatedUserLicense">Allocated user license</label>
            <div>
              <input type="text" class="form-control" name="AllocatedUserLicense" [(ngModel)]="allocatedUserLicense"
                #AllocatedUserLicense="ngModel" maxlength="249" />
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Power BI Configuration</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="Throughput">Throughput Summary Report URL</label>
            <div>
              <input id="Throughput" name="Throughput" #Throughput="ngModel" type="text" class="form-control"
                placeholder="Throughput Summary Report URL" [(ngModel)]="ServiceProvider.ThroughputSummaryURL"
                autocomplete="off" [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="Throughput.errors && (Throughput.dirty || Throughput.touched)" class="alert alert-danger">
                <div [hidden]="!Throughput.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="Cleaning">Cleaning Summary Report URL</label>
            <div>
              <input id="Cleaning" name="Cleaning" #Cleaning="ngModel" type="text" class="form-control"
                placeholder="Cleaning Summary Report URL" [(ngModel)]="ServiceProvider.CleaningSummaryURL"
                autocomplete="off" [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="Cleaning.errors && (Cleaning.dirty || Cleaning.touched)" class="alert alert-danger">
                <div [hidden]="!Cleaning.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="Survey">Survey Summary Report URL</label>
            <div>
              <input id="Survey" name="Survey" #Survey="ngModel" type="text" class="form-control"
                placeholder="Survey Summary Report URL" [(ngModel)]="ServiceProvider.SurveySummaryURL"
                autocomplete="off" [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="Survey.errors && (Survey.dirty || Survey.touched)" class="alert alert-danger">
                <div [hidden]="!Survey.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="Predictive">Predictive Summary Report URL</label>
            <div>
              <input id="Predictive" name="Predictive" #Predictive="ngModel" type="text" class="form-control"
                placeholder="Predictive Summary Report URL" [(ngModel)]="ServiceProvider.PredectiveSummaryURL"
                autocomplete="off" [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="Predictive.errors && (Predictive.dirty || Predictive.touched)" class="alert alert-danger">
                <div [hidden]="!Predictive.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="Survey">Inspection Summary Report URL</label>
            <div>
              <input id="Inspection" name="Inspection" #Inspection="ngModel" type="text" class="form-control"
                placeholder="Inspection Summary Report URL" [(ngModel)]="ServiceProvider.InspectionSummaryURL"
                autocomplete="off" [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="Inspection.errors && (Inspection.dirty || Inspection.touched)" class="alert alert-danger">
                <div [hidden]="!Inspection.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="Survey">Zurn Flush Valve Summary Report URL</label>
            <div>
              <input id="ZurnFlushValveSummaryReportURL" name="ZurnFlushValveSummaryReportURL"
                #ZurnFlushValveSummaryReportURL="ngModel" type="text" class="form-control"
                placeholder="Zurn Flush Valve Summary Report URL"
                [(ngModel)]="ServiceProvider.ZurnFlushValveSummaryReportURL" autocomplete="off"
                [pattern]="utilitiesService.webSiteRegex" [readonly]="!isSysAdmin" />
              <div *ngIf="
                  ZurnFlushValveSummaryReportURL.errors &&
                  (ZurnFlushValveSummaryReportURL.dirty || ZurnFlushValveSummaryReportURL.touched)
                " class="alert alert-danger">
                <div [hidden]="!ZurnFlushValveSummaryReportURL.errors.pattern" class="text-danger">
                  {{ utilitiesService.webSiteRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" *ngIf="isInspectionColorConfigShow" />
        <h3 *ngIf="isInspectionColorConfigShow">Inspection Score Color Code Configuration</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isInspectionColorConfigShow">
          <div class="col-lg-6 col-md-6 col-sm-6 box-body table-responsive form-group">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Color</th>
                  <th style="width: 42%">Minimum %</th>
                  <th style="width: 42%">Maximum %</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Red</td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="RedColourMin"
                        placeholder="Minimum in %" [(ngModel)]="ServiceProvider.RedColourMin" #RedColourMin="ngModel"
                        minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="RedColourMin.errors && (RedColourMin.dirty || RedColourMin.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!RedColourMin.errors.required" class="text-danger">
                          Red color minimum value is required.
                        </div>
                        <div [hidden]="!RedColourMin.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="RedColourMax"
                        placeholder="Maximum in %" [(ngModel)]="ServiceProvider.RedColourMax" #RedColourMax="ngModel"
                        minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="RedColourMax.errors && (RedColourMax.dirty || RedColourMax.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!RedColourMax.errors.required" class="text-danger">
                          Red color maximum value is required.
                        </div>
                        <div [hidden]="!RedColourMax.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Yellow</td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="YellowColourMin"
                        placeholder="Minimum in %" [(ngModel)]="ServiceProvider.YellowColourMin"
                        #YellowColourMin="ngModel" minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="YellowColourMin.errors && (YellowColourMin.dirty || YellowColourMin.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!YellowColourMin.errors.required" class="text-danger">
                          Yellow color minimum value is required.
                        </div>
                        <div [hidden]="!YellowColourMin.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="YellowColourMax"
                        placeholder="Maximum in %" [(ngModel)]="ServiceProvider.YellowColourMax"
                        #YellowColourMax="ngModel" minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="YellowColourMax.errors && (YellowColourMax.dirty || YellowColourMax.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!YellowColourMax.errors.required" class="text-danger">
                          Yellow color maximum value is required.
                        </div>
                        <div [hidden]="!YellowColourMax.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Green</td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="GreenColourMin"
                        placeholder="Minimum in %" [(ngModel)]="ServiceProvider.GreenColourMin"
                        #GreenColourMin="ngModel" minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="GreenColourMin.errors && (GreenColourMin.dirty || GreenColourMin.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!GreenColourMin.errors.required" class="text-danger">
                          Green colour minimum value is required.
                        </div>
                        <div [hidden]="!GreenColourMin.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input type="text" autocomplete="invalid" class="form-control" name="GreenColourMax"
                        placeholder="Maximum in %" [(ngModel)]="ServiceProvider.GreenColourMax"
                        #GreenColourMax="ngModel" minlength="0" maxlength="3" pattern="^(\d{0,2}(\d{1,2})?|100(\?)?)$"
                        [disabled]="formFieldDisabled" required />
                      <div *ngIf="GreenColourMax.errors && (GreenColourMax.dirty || GreenColourMax.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!GreenColourMax.errors.required" class="text-danger">
                          Green colour maximum value is required.
                        </div>
                        <div [hidden]="!GreenColourMax.errors.pattern" class="text-danger">
                          Accepts only numeric and in between 0 to 100.
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Module Assignment</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group accessPermissns">
          <div class="col-6">
            <div class="form-group">
              <div class="d-inline-block form-group">
                <div *ngIf="isCheckBoxVisible && isSysAdmin" style="margin-left: -15px">
                  <mat-checkbox class="form-check-input" [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="isAllFeatureConfigurationChecked"
                    (change)="onAllCheckedChange($event)">All</mat-checkbox>
                </div>
                <ngx-treeview #treeviewComponent [items]="items" [config]="config" [itemTemplate]="itemTemplate"
                  (selectedChange)="onSelectedChange($event)">
                </ngx-treeview>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-xs-12 form-group" *ngIf="ServiceProvider.ServiceProviderId">
            <div>
              <div>
                <mat-checkbox [(ngModel)]="ServiceProvider.IsActive" name="isActive"> Is Active</mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 btn-section">
          <input type="button" value="Cancel" class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(serviceproviderForm)" />
          <input type="button" value="Save" *ngIf="!ServiceProvider.ServiceProviderId" class="btn btn-type btn-save"
            [disabled]="!serviceproviderForm.form.valid || isReqComplete"
            (click)="addServiceProvider(serviceproviderForm)" />
          <!-- || !ServiceProvider.Subscription.SubscriptionId"-->
          <input type="button" value="Save" *ngIf="ServiceProvider.ServiceProviderId" class="btn btn-type btn-save"
            [disabled]="!serviceproviderForm.form.valid || isReqComplete || !(TraxPermission.SERVICE_PROVIDER_EDIT | canAccess)"
            (click)="updateServiceProvider(serviceproviderForm)" />
          <!-- || !ServiceProvider.Subscription.SubscriptionId-->
        </div>
      </div>
    </form>
  </div>
</section>

<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item" style="display: inline-flex">
    <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa"
      [class.fa-caret-right]="item.collapsed" [class.fa-caret-down]="!item.collapsed"></i>
    <div class="form-check">
      <mat-checkbox class="form-check-input" [(ngModel)]="item.checked" (change)="onCheckedChange()"
        [disabled]="item.disabled" [indeterminate]="item.indeterminate">
        {{ item.text }}</mat-checkbox>
    </div>
  </div>
</ng-template>