export enum TraxPermission {
  //admin level
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  TASK = 'task',
  INVENTORY = 'inventory',
  SENSOR_TYPE = 'sensor-type',
  SENSOR_MANUFACTURER = 'sensor-manufacturer',
  SURVEY_QUESTION_CONFIGURATION = 'survey-question-configuration',
  SURVEY_QUESTION_RESPONSE_CONFIGURATION = 'survey-question-response-configuration',
  USER_BILLING_CUSTOM_REPORT = 'user-billing-custom-report',
  SUBSCRIPTION = 'subscription',
  INSPECTION_SCORE_FACTOR_CONFIGURATION = 'inspection-score-factor-configuration',

  //end admin level
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  USER = 'user',
  ROLE = 'role',
  DEVICE_REGISTRATION = 'device-registration',
  SHIFT = 'shift',
  SHIFT_BREAK_SCHEDULE = 'shift-break-schedule',
  STAFF_SCHEDULE = 'staff-schedule',
  VENUE = 'venue',
  BUILDING = 'building',
  ZONE = 'zone',
  BEACON = 'beacon',
  LOCATION = 'location',
  CLEANING_ALERT = 'cleaning-alert',
  ASSET = 'asset',
  ASSET_TYPE = 'asset-type',
  PRODUCT = 'product',
  PRODUCT_MODEL = 'product-model',
  PRODUCT_MANUFACTURER = 'product-manufacturer',
  SUPPLIER = 'supplier',
  LEVEL_ASSIGNMENT = 'level-assignment',
  EMERGENCY_ALERT = 'emergency-alert',
  MANUAL_DUTY_SWITCH = 'manual-duty-switch',
  DASHBOARD_CONTEXT_MENU = 'dashboard-context-menu',
  WORKORDER_TYPE = 'workorder-type',
  WORKORDER_CATEGORY = 'workorder-category',
  CLOSURE_TEMPLATE = 'closure-template',
  WORKORDER = 'workorder',
  VENUE_MAP = 'venue-map',
  CONFIGURATION = 'configuration',
  CREATE_WORKORDER = 'create-workorder',
  INVENTORY_TOPUP = 'inventory-topup',
  QC_WORKORDER = 'qc-workorder',
  QC_CLEANING_ALERT = 'qc-cleaning-alert',
  IMPORT_DATA = 'import-data',
  CORPORATION = 'corporation',
  SENSOR = 'sensor',
  ALERT_TEMPLATE = 'alert-template',
  STATISTICS = 'statistics',
  RESTROOM_OVERVIEW = 'restroom-overview',
  USERLOCATIONASSIGNMENT = 'userlocationassignment',
  INFOR_WORKORDER = 'infor-workorder',
  THROUGHPUT_SUMMARY_REPORT = 'throughput-summary-report',
  SURVEY_SUMMARY_REPORT = 'survey-summary-report',
  CLEANING_SUMMARY_REPORT = 'cleaning-summary-report',
  PREDICTIVE_FLIGHT_SUMMARY_REPORT = 'predictive-flight-summary-report',
  MOBILE_CHAT_BOX = 'mobile-chat-box',
  STALL_OCCUPANCY_STATUS = 'stall-occupancy-status',
  LOCATION_STATUS = 'location-status',
  PREDICTIVE_ANALYTICS = 'predictive-analytics',
  THROUGHPUT_CUSTOM_REPORT = 'throughput-custom-report',
  FEEDBACK_CUSTOM_REPORT = 'feedback-custom-report',
  CLEANING_ALERT_CUSTOM_REPORT = 'cleaning-alert-custom-report',
  TASK_CUSTOM_REPORT = 'task-custom-report',
  INVENTORY_CUSTOM_REPORT = 'inventory-custom-report',
  DISPENSER_CUSTOM_REPORT = 'dispenser-custom-report',
  TRASH_CUSTOM_REPORT = 'trash-custom-report',
  BADGE = 'badge',
  HEAT_MAP_REPORT = 'heat-map-report',
  GATEWAY = 'gateway',
  GATE = 'gate',
  LOCATION_GATE_GROUP = 'location-gate-group',
  SERVICE_PROVIDER = 'service-provider',
  VENUE_ELEMENT_CONFIGURATION = 'venue-element-configuration',
  VENUE_TASK_CONFIGURATION = 'venue-task-configuration',
  VENUE_INVENTORY_CONFIGURATION = 'venue-inventory-configuration',
  CALENDAR = 'calendar',
  INSPECTION = 'inspection',
  INSPECTION_STATISTICS = 'inspection-statistics',
  INSPECTION_LOG = 'inspection-log',
  LOCATION_OVERVIEW = 'location-overview',
  INSPECTION_LOG_REPORT = 'inspection-log-report',
  INSPECTORLOCATIONASSIGNMENT = 'inspectorlocationassignment',
  QUALITY_CHECK_LOG = 'quality-check-log',
  LOCATION_TYPE = 'location-type',
  LOCATION_TEMPLATE = 'location-template',
  FEEDBACK_CONFIGURATION = 'feedback-configuration',
  VENUE_FEEDBACK_REASON = 'venue-feedback-reason',
  INSPECTION_DASHBOARD = 'inspection-dashboard',
  INSPECTION_SUMMARY_REPORT = 'inspection-summary-report',
  OVERVIEW_DASHBOARD = 'overview-dashboard',
  BADGE_SUMMARY = 'badge-summary',
  USER_LICENSE_REQUEST_LOG = 'user-license-request-log',
  FLUSH_VALVE_CUSTOM_REPORT = 'flush-valve-custom-report',
  FLUSH_VALVE_SUMMARY_REPORT = 'flush-valve-summary-report',
  CUSTOM_REPORTS = 'CUSTOM_REPORTS',
  VENUE_SURVEY_CONFIGURATION = 'venuesurveyconfiguration',
  CALENDAR_SCHEDULE_ADD = 'CALENDAR_SCHEDULE_ADD',
  CALENDAR_SCHEDULE_VIEW = 'CALENDAR_SCHEDULE_VIEW',
  CALENDAR_VIEW_MY_RECORDS = 'CALENDAR_VIEW_MY_RECORDS',
  CALENDAR_VIEW_ALL_RECORDS = 'CALENDAR_VIEW_ALL_RECORDS',
  CALENDAR_SCHEDULE_EDIT = 'CALENDAR_SCHEDULE_EDIT',
  CALENDAR_SCHEDULE_DELETE = 'CALENDAR_SCHEDULE_DELETE',
  SERVICE_PROVIDER_VIEW = 'SERVICE_PROVIDER_VIEW',
  SERVICE_PROVIDER_EDIT = 'SERVICE_PROVIDER_EDIT',
  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_ADD = 'ROLE_ADD',
  ROLE_EDIT = 'ROLE_EDIT',
  USER_VIEW = 'USER_VIEW',
  USER_ADD = 'USER_ADD',
  USER_EDIT = 'USER_EDIT',
  USER_PRINT_QR_CARDS = 'USER_PRINT_QR_CARDS',
  CORPORATION_VIEW = 'CORPORATION_VIEW',
  CORPORATION_ADD = 'CORPORATION_ADD',
  CORPORATION_EDIT = 'CORPORATION_EDIT',
  CORPORATION_VIEW_INTEGRATION_KEY = 'CORPORATION_VIEW_INTEGRATION_KEY',
  CORPORATION_ADD_INTEGRATION_KEY = 'CORPORATION_ADD_INTEGRATION_KEY',
  CORPORATION_EDIT_INTEGRATION_KEY = 'CORPORATION_EDIT_INTEGRATION_KEY',
  CORPORATION_DELETE_INTEGRATION_KEY = 'CORPORATION_DELETE_INTEGRATION_KEY',
  VENUE_VIEW = 'VENUE_VIEW',
  VENUE_ADD = 'VENUE_ADD',
  VENUE_EDIT = 'VENUE_EDIT',
  BUILDING_VIEW = 'BUILDING_VIEW',
  BUILDING_ADD = 'BUILDING_ADD',
  BUILDING_EDIT = 'BUILDING_EDIT',
  ZONE_VIEW = 'ZONE_VIEW',
  ZONE_ADD = 'ZONE_ADD',
  ZONE_EDIT = 'ZONE_EDIT',
  LEVEL_ASSIGNMENT_VIEW = 'LEVEL_ASSIGNMENT_VIEW',
  LEVEL_ASSIGNMENT_ADD = 'LEVEL_ASSIGNMENT_ADD',
  LEVEL_ASSIGNMENT_EDIT = 'LEVEL_ASSIGNMENT_EDIT',
  SHIFT_VIEW = 'SHIFT_VIEW',
  SHIFT_ADD = 'SHIFT_ADD',
  SHIFT_EDIT = 'SHIFT_EDIT',
  SHIFT_BRK_SCHEDULE_VIEW = 'SHIFT_BRK_SCHEDULE_VIEW',
  SHIFT_BRK_SCHEDULE_ADD = 'SHIFT_BRK_SCHEDULE_ADD',
  SHIFT_BRK_SCHEDULE_EDIT = 'SHIFT_BRK_SCHEDULE_EDIT',
  STAFF_SCHEDULE_VIEW = 'STAFF_SCHEDULE_VIEW',
  STAFF_SCHEDULE_ADD = 'STAFF_SCHEDULE_ADD',
  STAFF_SCHEDULE_EDIT = 'STAFF_SCHEDULE_EDIT',
  BEACON_VIEW = 'BEACON_VIEW',
  BEACON_ADD = 'BEACON_ADD',
  BEACON_EDIT = 'BEACON_EDIT',
  LOCATION_VIEW_MY_RECORDS = 'LOCATION_VIEW_MY_RECORDS',
  LOCATION_VIEW_ALL_RECORDS = 'LOCATION_VIEW_ALL_RECORDS',
  LOCATION_ADD = 'LOCATION_ADD',
  LOCATION_EDIT = 'LOCATION_EDIT',
  CLEANING_ALERT_VIEW_MY_RECORDS = 'CLEANING_ALERT_VIEW_MY_RECORDS',
  CLEANING_ALERT_VIEW_ALL_RECORDS = 'CLEANING_ALERT_VIEW_ALL_RECORDS',
  CLEANING_ALERT_SYSTEM_AUTO_ASSIGN = 'CLEANING_ALERT_SYSTEM_AUTO_ASSIGN',
  CLEANING_ALERT_ASSIGN_MANUALLY = 'CLEANING_ALERT_ASSIGN_MANUALLY',
  CLEANING_ALERT_ADD = 'CLEANING_ALERT_ADD',
  CLEANING_ALERT_MODIFY = 'CLEANING_ALERT_MODIFY',
  CLEANING_ALERT_VIEW_OPEN_STATUS = 'CLEANING_ALERT_VIEW_OPEN_STATUS',
  CLEANING_ALERT_VIEW_ASSIGNED_STATUS = 'CLEANING_ALERT_VIEW_ASSIGNED_STATUS',
  CLEANING_ALERT_VIEW_DECLINED_STATUS = 'CLEANING_ALERT_VIEW_DECLINED_STATUS',
  CLEANING_ALERT_VIEW_INPROGRESS_STATUS = 'CLEANING_ALERT_VIEW_INPROGRESS_STATUS',
  CLEANING_ALERT_VIEW_COMPLETE_STATUS = 'CLEANING_ALERT_VIEW_COMPLETE_STATUS',
  CLEANING_ALERT_VIEW_CANCEL_STATUS = 'CLEANING_ALERT_VIEW_CANCEL_STATUS',
  QUALITY_CHECK_VIEW_MY_RECORDS = 'QUALITY_CHECK_VIEW_MY_RECORDS',
  QUALITY_CHECK_VIEW_ALL_RECORDS = 'QUALITY_CHECK_VIEW_ALL_RECORDS',
  QUALITY_CHECK_ADD = 'QUALITY_CHECK_ADD',
  QUALITY_CHECK_SYSTEM_AUTO_ASSIGN = 'QUALITY_CHECK_SYSTEM_AUTO_ASSIGN',
  QUALITY_CHECK_ASSIGN_MANUALLY = 'QUALITY_CHECK_ASSIGN_MANUALLY',
  QUALITY_CHECK_MODIFY = 'QUALITY_CHECK_MODIFY',
  QUALITY_CHECK_VIEW_AWAITING_QC_STATUS = 'QUALITY_CHECK_VIEW_AWAITING_QC_STATUS',
  QUALITY_CHECK_VIEW_QC_ASSIGNED_STATUS = 'QUALITY_CHECK_VIEW_QC_ASSIGNED_STATUS',
  QUALITY_CHECK_VIEW_QC_INPROGRESS_STATUS = 'QUALITY_CHECK_VIEW_QC_INPROGRESS_STATUS',
  QUALITY_CHECK_LOG_VIEW_QC_REJECTED_STATUS = 'QUALITY_CHECK_LOG_VIEW_QC_REJECTED_STATUS',
  QUALITY_CHECK_LOG_VIEW_QC_APPROVED_STATUS = 'QUALITY_CHECK_LOG_VIEW_QC_APPROVED_STATUS',
  QUALITY_CHECK_LOG_VIEW_QC_CANCEL_STATUS = 'QUALITY_CHECK_LOG_VIEW_QC_CANCEL_STATUS',
  ASSET_VIEW = 'ASSET_VIEW',
  ASSET_ADD = 'ASSET_ADD',
  ASSET_EDIT = 'ASSET_EDIT',
  ASSET_TYPE_VIEW = 'ASSET_TYPE_VIEW',
  ASSET_TYPE_ADD = 'ASSET_TYPE_ADD',
  ASSET_TYPE_EDIT = 'ASSET_TYPE_EDIT',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  PRODUCT_ADD = 'PRODUCT_ADD',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_MODEL_VIEW = 'PRODUCT_MODEL_VIEW',
  PRODUCT_MODEL_ADD = 'PRODUCT_MODEL_ADD',
  PRODUCT_MODEL_EDIT = 'PRODUCT_MODEL_EDIT',
  PRODUCT_MANUFACTURER_VIEW = 'PRODUCT_MANUFACTURER_VIEW',
  PRODUCT_MANUFACTURER_ADD = 'PRODUCT_MANUFACTURER_ADD',
  PRODUCT_MANUFACTURER_EDIT = 'PRODUCT_MANUFACTURER_EDIT',
  SUPPLIER_VIEW = 'SUPPLIER_VIEW',
  SUPPLIER_ADD = 'SUPPLIER_ADD',
  SUPPLIER_EDIT = 'SUPPLIER_EDIT',
  WORK_ORDER_TYPES_VIEW = 'WORK_ORDER_TYPES_VIEW',
  WORK_ORDER_TYPES_ADD = 'WORK_ORDER_TYPES_ADD',
  WORK_ORDER_TYPES_EDIT = 'WORK_ORDER_TYPES_EDIT',
  WORK_ORDER_CATEGORY_VIEW = 'WORK_ORDER_CATEGORY_VIEW',
  WORK_ORDER_CATEGORY_ADD = 'WORK_ORDER_CATEGORY_ADD',
  WORK_ORDER_CATEGORY_EDIT = 'WORK_ORDER_CATEGORY_EDIT',
  CLOSURE_TEMPLATE_VIEW = 'CLOSURE_TEMPLATE_VIEW',
  CLOSURE_TEMPLATE_ADD = 'CLOSURE_TEMPLATE_ADD',
  CLOSURE_TEMPLATE_EDIT = 'CLOSURE_TEMPLATE_EDIT',
  WORK_ORDER_VIEW_MY_RECORDS = 'WORK_ORDER_VIEW_MY_RECORDS',
  WORK_ORDER_VIEW_ALL_RECORDS = 'WORK_ORDER_VIEW_ALL_RECORDS',
  WORK_ORDER_EDIT = 'WORK_ORDER_EDIT',
  WORK_ORDER_ASSIGN_MANUALLY = 'WORK_ORDER_ASSIGN_MANUALLY',
  WORK_ORDER_VIEW_OPEN_STATUS = 'WORK_ORDER_VIEW_OPEN_STATUS',
  WORK_ORDER_VIEW_INPROGRESS_STATUS = 'WORK_ORDER_VIEW_INPROGRESS_STATUS',
  WORK_ORDER_VIEW_COMPLETE_STATUS = 'WORK_ORDER_VIEW_COMPLETE_STATUS',
  WORK_ORDER_CREATE_FROM_MAP = 'WORK_ORDER_CREATE_FROM_MAP',
  QC_WORK_ORDER_VIEW_MY_RECORDS = 'QC_WORK_ORDER_VIEW_MY_RECORDS',
  QC_WORK_ORDER_VIEW_ALL_RECORDS = 'QC_WORK_ORDER_VIEW_ALL_RECORDS',
  QC_WORK_ORDER_ADD = 'QC_WORK_ORDER_ADD',
  QC_WORK_ORDER_MODIFY = 'QC_WORK_ORDER_MODIFY',
  QC_WORK_ORDER_VIEW_QC_INPROGRESS_STATUS = 'QC_WORK_ORDER_VIEW_QC_INPROGRESS_STATUS',
  QC_WORK_ORDER_VIEW_QC_REJECTED_STATUS = 'QC_WORK_ORDER_VIEW_QC_REJECTED_STATUS',
  QC_WORK_ORDER_VIEW_QC_APPROVED_STATUS = 'QC_WORK_ORDER_VIEW_QC_APPROVED_STATUS',
  IMPORT_DATA_FILE_DOWNLOAD = 'IMPORT_DATA_FILE_DOWNLOAD',
  IMPORT_DATA_ALLOW = 'IMPORT_DATA_ALLOW',
  SENSORS_VIEW = 'SENSORS_VIEW',
  SENSORS_ADD = 'SENSORS_ADD',
  SENSORS_EDIT = 'SENSORS_EDIT',
  ALERT_TEMPLATE_VIEW = 'ALERT_TEMPLATE_VIEW',
  ALERT_TEMPLAT_ADD = 'ALERT_TEMPLAT_ADD',
  ALERT_TEMPLAT_EDIT = 'ALERT_TEMPLAT_EDIT',
  LOCATION_ASGMT_VIEW_USERS_RECORDS = 'LOCATION_ASGMT_VIEW_USERS_RECORDS',
  LOCATION_ASGMT_VIEW_UNASSIGNED_LOCATION = 'LOCATION_ASGMT_VIEW_UNASSIGNED_LOCATION',
  LOCATION_ASGMT_EDIT = 'LOCATION_ASGMT_EDIT',
  GATEWAY_VIEW = 'GATEWAY_VIEW',
  GATEWAY_ADD = 'GATEWAY_ADD',
  GATEWAY_EDIT = 'GATEWAY_EDIT',
  BADGE_VIEW = 'BADGE_VIEW',
  BADGE_ADD = 'BADGE_ADD',
  BADGE_EDIT = 'BADGE_EDIT',
  HEAT_MAP_VIEW_GATE_COUNT = 'HEAT_MAP_VIEW_GATE_COUNT',
  VENUE_TASK_VIEW = 'VENUE_TASK_VIEW',
  VENUE_TASK_ADD = 'VENUE_TASK_ADD',
  VENUE_TASK_EDIT = 'VENUE_TASK_EDIT',
  VENUE_TASK_DELETE = 'VENUE_TASK_DELETE',
  VENUE_INVENTORIE_VIEW = 'VENUE_INVENTORIE_VIEW',
  VENUE_INVENTORIE_ADD = 'VENUE_INVENTORIE_ADD',
  VENUE_INVENTORIE_EDIT = 'VENUE_INVENTORIE_EDIT',
  VENUE_INVENTORIE_DELETE = 'VENUE_INVENTORIE_DELETE',
  VENUE_ELEMENT_VIEW = 'VENUE_ELEMENT_VIEW',
  VENUE_ELEMENT_ADD = 'VENUE_ELEMENT_ADD',
  VENUE_ELEMENT_EDIT = 'VENUE_ELEMENT_EDIT',
  VENUE_ELEMENT_DELETE = 'VENUE_ELEMENT_DELETE',
  GATE_VIEW = 'GATE_VIEW',
  GATE_ADD = 'GATE_ADD',
  GATE_EDIT = 'GATE_EDIT',
  LOCATION_GATE_GROUP_VIEW = 'LOCATION_GATE_GROUP_VIEW',
  LOCATION_GATE_GROUP_ADD = 'LOCATION_GATE_GROUP_ADD',
  LOCATION_GATE_GROUP_EDIT = 'LOCATION_GATE_GROUP_EDIT',
  INSPECTION_LOG_VIEW_MY_RECORDS = 'INSPECTION_LOG_VIEW_MY_RECORDS',
  INSPECTION_LOG_VIEW_ALL_RECORDS = 'INSPECTION_LOG_VIEW_ALL_RECORDS',
  INSPECTION_ADD = 'INSPECTION_ADD',
  INSPECTION_ASSIGN_MANUALLY = 'INSPECTION_ASSIGN_MANUALLY',
  INSPECTION_MODIFY = 'INSPECTION_MODIFY',
  INSPECTION_VIEW_OPEN_STATUS = 'INSPECTION_VIEW_OPEN_STATUS',
  INSPECTION_VIEW_ASSIGNED_STATUS = 'INSPECTION_VIEW_ASSIGNED_STATUS',
  INSPECTION_VIEW_INPROGRESS_STATUS = 'INSPECTION_VIEW_INPROGRESS_STATUS',
  INSPECTION_LOG_VIEW_FOLLOW_UP_STATUS = 'INSPECTION_LOG_VIEW_FOLLOW_UP_STATUS',
  INSPECTION_LOG_VIEW_COMPLETE_STATUS = 'INSPECTION_LOG_VIEW_COMPLETE_STATUS',
  INSPECTION_LOG_VIEW_CANCEL_STATUS = 'INSPECTION_LOG_VIEW_CANCEL_STATUS',
  LOCATION_TYPE_VIEW = 'LOCATION_TYPE_VIEW',
  LOCATION_TYPE_ADD = 'LOCATION_TYPE_ADD',
  LOCATION_TYPE_EDIT = 'LOCATION_TYPE_EDIT',
  LOCATION_TEMPLATE_VIEW = 'LOCATION_TEMPLATE_VIEW',
  LOCATION_TEMPLATE_ADD = 'LOCATION_TEMPLATE_ADD',
  LOCATION_TEMPLATE_EDIT = 'LOCATION_TEMPLATE_EDIT',
  VENUE_FEEDBACK_REASON_VIEW = 'VENUE_FEEDBACK_REASON_VIEW',
  VENUE_FEEDBACK_REASON_ADD = 'VENUE_FEEDBACK_REASON_ADD',
  VENUE_FEEDBACK_REASON_EDIT = 'VENUE_FEEDBACK_REASON_EDIT',
  VENUE_FEEDBACK_REASON_DELETE = 'VENUE_FEEDBACK_REASON_DELETE',
  USER_LICENSE_REQUEST_VIEW = 'USER_LICENSE_REQUEST_VIEW',
  USER_LICENSE_REQUEST = 'USER_LICENSE_REQUEST',
  CLEANING_ALERT_VIEW_MY_LOCATIONS = 'CLEANING_ALERT_VIEW_MY_LOCATIONS',
  INSPECTION_VIEW_MY_LOCATIONS = 'INSPECTION_VIEW_MY_LOCATIONS',
  QUALITY_CHECK_VIEW_MY_LOCATIONS = 'QUALITY_CHECK_VIEW_MY_LOCATIONS',
  CALENDAR_VIEW_MY_LOCATIONS = 'CALENDAR_VIEW_MY_LOCATIONS',
  INSPECTION_LOCATION_ASSIGNMENT_VIEW_USERS_RECORDS = 'INSPECTION_LOCATION_ASGMT_VIEW_USERS_RECORDS',
  INSPECTION_LOCATION_ASGMT_EDIT = 'INSPECTION_LOCATION_ASGMT_EDIT',
  CLEANING_ALERT_ADD_WITH_SELF_ASSIGN = 'CLEANING_ALERT_ADD_WITH_SELF_ASSIGN',
  QUALITY_CHECK_LOG_VIEW_MY_RECORDS = 'QUALITY_CHECK_LOG_VIEW_MY_RECORDS',
  QUALITY_CHECK_LOG_VIEW_ALL_RECORDS = 'QUALITY_CHECK_LOG_VIEW_ALL_RECORDS',
  QUALITY_CHECK_LOG_VIEW_MY_LOCATIONS = 'QUALITY_CHECK_LOG_VIEW_MY_LOCATIONS',
  QUALITY_CHECK_LOG_MODIFY = 'QUALITY_CHECK_LOG_MODIFY',
  QUALITY_CHECK_LOG_VIEW_AWAITING_QC_STATUS = 'QUALITY_CHECK_LOG_VIEW_AWAITING_QC_STATUS',
  QUALITY_CHECK_LOG_VIEW_QC_ASSIGNED_STATUS = 'QUALITY_CHECK_LOG_VIEW_QC_ASSIGNED_STATUS',
  QUALITY_CHECK_LOG_VIEW_QC_INPROGRESS_STATUS = 'QUALITY_CHECK_LOG_VIEW_QC_INPROGRESS_STATUS',
  INSPECTION_VIEW_MY_RECORDS = 'INSPECTION_VIEW_MY_RECORDS',
  INSPECTION_VIEW_ALL_RECORDS = 'INSPECTION_VIEW_ALL_RECORDS',
  INSPECTION_LOG_VIEW_MY_LOCATIONS = 'INSPECTION_LOG_VIEW_MY_LOCATIONS',
  INSPECTION_LOG_MODIFY = 'INSPECTION_LOG_MODIFY',
  INSPECTION_LOG_VIEW_OPEN_STATUS = 'INSPECTION_LOG_VIEW_OPEN_STATUS',
  INSPECTION_LOG_VIEW_ASSIGNED_STATUS = 'INSPECTION_LOG_VIEW_ASSIGNED_STATUS',
  INSPECTION_LOG_VIEW_INPROGRESS_STATUS = 'INSPECTION_LOG_VIEW_INPROGRESS_STATUS',
  SHARE_USER = 'share-user',
  ACQUIRED_USERS = 'acquired-users',
  VIEW_SHARED_USERS = 'VIEW_SHARED_USERS',
  ADD_USER_TO_SHARE = 'ADD_USER_TO_SHARE',
  EDIT_SHARED_USER = 'EDIT_SHARED_USER',
  VIEW_ACQUIRED_USERS = 'VIEW_ACQUIRED_USERS',
  EDIT_ACQUIRED_USERS = 'EDIT_ACQUIRED_USERS',
  TRANSFER_OWNERSHIP = 'transfer-ownership',
  EDIT_TRANSFER_OWNERSHIP = 'EDIT_TRANSFER_OWNERSHIP',
  STAFF_SCHEDULE_ASSIGNMENT_VIEW_USERS_RECORDS = 'STAFF_SCHEDULE_ASGMT_VIEW_USERS_RECORDS',
  LOCATION_ASSIGNMENT_ASSIGN_SELF = 'LOCATION_ASGMT_ASSIGN_SELF',
  LOCATION_ASSIGNMENT_ASSIGN_TO_OTHERS = 'LOCATION_ASGMT_ASSIGN_TO_OTHERS',
  INSPECTION_LOCATION_ASSIGNMENT_SELF = 'INSPECTION_LOCATION_ASGMT_SELF',
  INSPECTION_LOCATION_ASSIGNMENT_TO_OTHERS = 'INSPECTION_LOCATION_ASGMT_TO_OTHERS',
  STAFF_SCHEDULE_ASSIGNMENT_SELF = 'STAFF_SCHEDULE_ASGMT_SELF',
  STAFF_SCHEDULE_ASSIGNMENT_TO_OTHERS = 'STAFF_SCHEDULE_ASGMT_TO_OTHERS',
  USER_ROLE_PERMISSION_TEMPLATE = 'user-role-permission-template',
  USER_ROLE_PERMISSION_TEMPLATE_VIEW = 'USER_ROLE_PERMISSION_TEMPLATE_VIEW',
  USER_ROLE_PERMISSION_TEMPLATE_ADD = 'USER_ROLE_PERMISSION_TEMPLATE_ADD',
  USER_ROLE_PERMISSION_TEMPLATE_EDIT = 'USER_ROLE_PERMISSION_TEMPLATE_EDIT',
  STAT_THROUGHPUT_VIEW_ALL_RECORDS = 'STAT_THROUGHPUT_VIEW_ALL_RECORDS',
  STAT_THROUGHPUT_VIEW_MY_LOCATIONS = 'STAT_THROUGHPUT_VIEW_MY_LOCATIONS',
  STAT_FEEDBACK_VIEW_ALL_RECORDS = 'STAT_FEEDBACK_VIEW_ALL_RECORDS',
  STAT_FEEDBACK_VIEW_MY_LOCATIONS = 'STAT_FEEDBACK_VIEW_MY_LOCATIONS',
  STAT_CLEANING_ALERT_VIEW_ALL_RECORDS = 'STAT_CLEANING_ALERT_VIEW_ALL_RECORDS',
  STAT_CLEANING_ALERT_VIEW_MY_RECORDS = 'STAT_CLEANING_ALERT_VIEW_MY_RECORDS',
  STAT_CLEANING_ALERT_VIEW_MY_LOCATIONS = 'STAT_CLEANING_ALERT_VIEW_MY_LOCATIONS',
  STAT_INSPECTION_VIEW_ALL_RECORDS = 'STAT_INS_VIEW_ALL_RECORDS',
  STAT_INSPECTION_VIEW_MY_RECORDS = 'STAT_INS_VIEW_MY_RECORDS',
  STAT_INSPECTION_VIEW_MY_LOCATIONS = 'STAT_INS_VIEW_MY_LOCATIONS',
  STAT_PREDICTIVE_FLIGHT_VIEW_ALL_RECORDS = 'STAT_PREDICTIVE_FLIGHT_VIEW_ALL_RECORDS',
  STAT_PREDICTIVE_FLIGHT_VIEW_MY_LOCATIONS = 'STAT_PREDICTIVE_FLIGHT_VIEW_MY_LOCATIONS',
  STAT_FLUSH_VALVE_VIEW_ALL_RECORDS = 'STAT_FLUSH_VALVE_VIEW_ALL_RECORDS',
  STAT_FLUSH_VALVE_VIEW_MY_LOCATIONS = 'STAT_FLUSH_VALVE_VIEW_MY_LOCATIONS',
  CUST_THROUGHPUT_VIEW_ALL_RECORDS = 'CUST_THROUGHPUT_VIEW_ALL_RECORDS',
  CUST_THROUGHPUT_VIEW_MY_LOCATIONS = 'CUST_THROUGHPUT_VIEW_MY_LOCATIONS',
  CUST_FEEDBACK_VIEW_ALL_RECORDS = 'CUST_FEEDBACK_VIEW_ALL_RECORDS',
  CUST_FEEDBACK_VIEW_MY_LOCATIONS = 'CUST_FEEDBACK_VIEW_MY_LOCATIONS',
  CUST_DISPENSER_VIEW_ALL_RECORDS = 'CUST_DISPENSER_VIEW_ALL_RECORDS',
  CUST_DISPENSER_VIEW_MY_LOCATIONS = 'CUST_DISPENSER_VIEW_MY_LOCATIONS',
  CUST_TRASH_VIEW_ALL_RECORDS = 'CUST_TRASH_VIEW_ALL_RECORDS',
  CUST_TRASH_VIEW_MY_LOCATIONS = 'CUST_TRASH_VIEW_MY_LOCATIONS',
  CUST_CLEANING_ALERT_VIEW_ALL_RECORDS = 'CUST_CLEANING_ALERT_VIEW_ALL_RECORDS',
  CUST_CLEANING_ALERT_VIEW_MY_RECORDS = 'CUST_CLEANING_ALERT_VIEW_MY_RECORDS',
  CUST_CLEANING_ALERT_VIEW_MY_LOCATIONS = 'CUST_CLEANING_ALERT_VIEW_MY_LOCATIONS',
  CUST_CLEANING_ALERT_TASK_VIEW_ALL_RECORDS = 'CUST_CLEANING_ALERT_TASK_VIEW_ALL_RECORDS',
  CUST_CLEANING_ALERT_TASK_VIEW_MY_RECORDS = 'CUST_CLEANING_ALERT_TASK_VIEW_MY_RECORDS',
  CUST_CLEANING_ALERT_TASK_VIEW_MY_LOCATIONS = 'CUST_CLEANING_ALERT_TASK_VIEW_MY_LOCATIONS',
  CUST_CLEANING_ALERT_INVENTORY_VIEW_ALL_RECORDS = 'CUST_CLEANING_ALERT_INVENTORY_VIEW_ALL_RECORDS',
  CUST_CLEANING_ALERT_INVENTORY_VIEW_MY_RECORDS = 'CUST_CLEANING_ALERT_INVENTORY_VIEW_MY_RECORDS',
  CUST_CLEANING_ALERT_INVENTORY_VIEW_MY_LOCATIONS = 'CUST_CLEANING_ALERT_INVENTORY_VIEW_MY_LOCATIONS',
  CUST_INSPECTION_VIEW_ALL_RECORDS = 'CUST_INSPECTION_VIEW_ALL_RECORDS',
  CUST_INSPECTION_VIEW_MY_RECORDS = 'CUST_INSPECTION_VIEW_MY_RECORDS',
  CUST_INSPECTION_VIEW_MY_LOCATIONS = 'CUST_INSPECTION_VIEW_MY_LOCATIONS',
  CUST_BADGE_VIEW_ALL_RECORDS = 'CUST_BADGE_VIEW_ALL_RECORDS',
  CUST_BADGE_VIEW_MY_LOCATIONS = 'CUST_BADGE_VIEW_MY_LOCATIONS',
  CUST_FLUSH_VALVE_VIEW_ALL_RECORDS = 'CUST_FLUSH_VALVE_VIEW_ALL_RECORDS',
  CUST_FLUSH_VALVE_VIEW_MY_LOCATIONS = 'CUST_FLUSH_VALVE_VIEW_MY_LOCATIONS',
  FEEDBACK_CONFIGURATION_VIEW = 'FEEDBACK_CONFIGURATION_VIEW',
  FEEDBACK_CONFIGURATION_EDIT = 'FEEDBACK_CONFIGURATION_EDIT',
  SCHEDULE_EVENTS_MENU = 'SCHEDULE_EVENTS_MENU',
  EQUIPMENT_PM_VIEW_USERS = 'EQUIPMENT_PM_VIEW_USERS',
  PROJECT_WORK_VIEW_USERS = 'PROJECT_WORK_VIEW_USERS',
  DELETE_USER = 'DELETE_USER',
  EVENTS_CUSTOM_REPORT = 'events-custom-report',
  CUST_EVENTS_VIEW_ALL_RECORDS = 'CUST_EVENTS_VIEW_ALL_RECORDS',
  CUST_EVENTS_VIEW_MY_RECORDS = 'CUST_EVENTS_VIEW_MY_RECORDS',
  CUST_EVENTS_VIEW_MY_LOCATIONS = 'CUST_EVENTS_VIEW_MY_LOCATIONS',
  USER_LOG_ACTIVITY_CUSTOM_REPORT = 'user-log-activity-custom-report',
  CUST_LOGIN_VIEW_ALL_RECORDS = 'CUST_LOGIN_VIEW_ALL_RECORDS',
  CUST_LOGIN_VIEW_MY_RECORDS = 'CUST_LOGIN_VIEW_MY_RECORDS',
  FEEDBACK_CONFIGURATION_ADD = 'FEEDBACK_CONFIGURATION_ADD',
  INSPECTION_NOTIFICATION_ALERT = 'inspection-notification-alert',
  VIEW_MY_LOCATIONS_USERS = 'VIEW_MY_LOCATIONS_USERS',
  CLEANING_ALERT_VIEW_CLOSED_NOT_COMPLETE_STATUS = 'CLEANING_ALERT_VIEW_CLOSED_NOT_COMPLETE_STATUS',
  EDIT_PROFILE = 'edit-profile',
  PROFILE_MENU = 'profile-menu',
  INSPECTION_DASHBOARD_VIEW_ALL_RECORDS = 'INSPECTION_DASHBOARD_VIEW_ALL_RECORDS',
  INSPECTION_DASHBOARD_VIEW_MY_RECORDS = 'INSPECTION_DASHBOARD_VIEW_MY_RECORDS',
  INSPECTION_DASHBOARD_VIEW_MY_LOCATIONS = 'INSPECTION_DASHBOARD_VIEW_MY_LOCATIONS',
  INSPECTION_STATISTICS_VIEW_ALL_RECORDS = 'INSPECTION_STATISTICS_VIEW_ALL_RECORDS',
  INSPECTION_STATISTICS_VIEW_MY_RECORDS = 'INSPECTION_STATISTICS_VIEW_MY_RECORDS',
  INSPECTION_STATISTICS_VIEW_MY_LOCATIONS = 'INSPECTION_STATISTICS_VIEW_MY_LOCATIONS',
  CUST_THROUGHPUT_COUNT = 'CUST_THROUGHPUT_COUNT',
  CUST_THROUGHPUT_STATUS_SUMMARY = 'CUST_THROUGHPUT_STATUS_SUMMARY',
  CUST_THROUGHPUT_COUNT_PER_DAY = 'CUST_THROUGHPUT_COUNT_PER_DAY',
  CUST_THROUGHPUT_COUNT_PER_HOUR = 'CUST_THROUGHPUT_COUNT_PER_HOUR',
  CUST_FEEDBACK_SUMMARY = 'CUST_FEEDBACK_SUMMARY',
  CUST_FEEDBACK_COUNT = 'CUST_FEEDBACK_COUNT',
  CUST_INSPECTION_SUMMARY = 'CUST_INSPECTION_SUMMARY',
  CUST_INSPECTION_INSPECTOR_REPORT_CARD = 'CUST_INSPECTION_INSPECTOR_REPORT_CARD',
  CUST_INSPECTION_GOAL_ACHIEVEMENT = 'CUST_INSPECTION_GOAL_ACHIEVEMENT',
  CUST_INSPECTION_INSPECTOR_AVERAGE_SCORE_CARD = 'CUST_INSPECTION_INSPECTOR_AVERAGE_SCORE_CARD',
  CUST_BADGE_SCAN_SUMMARY = 'CUST_BADGE_SCAN_SUMMARY',
  CUST_BADGE_SCAN_GOAL_ACHIEVEMENT = 'CUST_BADGE_SCAN_GOAL_ACHIEVEMENT',
  BADGE_SCAN_DAILY_REPORT = 'BADGE_SCAN_DAILY_REPORT',
  IMPORT_LOCATION_TYPES = 'IMPORT_LOCATION_TYPES',
  INDOOR_POSITIONING_MAP = 'indoor-positioning-map',
  INDOOR_NAVIGATION_MAP = 'indoor-navigation-map',
  USER_ATTENDANCE_REPORT = 'user-attendance-report',
  SEND_NEGATIVE_FEEDBACK_NOTIFICATION_TO_USER = 'send-negative-feedback-notification-to-user',
  CUST_AVIUS_FEEDBACK_SURVEY = 'CUST_AVIUS_FEEDBACK_SURVEY',
  AREA = 'area',
  AREA_VIEW = 'AREA_VIEW',
  AREA_ADD = 'AREA_ADD',
  AREA_EDIT = 'AREA_EDIT',
  CUST_AREA_WISE_INSPECTION_SCORE_REPORT = 'CUST_AREA_WISE_INSPECTION_SCORE_REPORT',
  EXPORT_LOCATIONS = 'EXPORT_LOCATIONS',
  PROJECT_WORK_LOG = 'project-work-log',
  PROJECT_WORK_VIEW_MY_RECORDS = 'PROJECT_WORK_VIEW_MY_RECORDS',
  PROJECT_WORK_VIEW_ALL_RECORDS = 'PROJECT_WORK_VIEW_ALL_RECORDS',
  PROJECT_WORK_VIEW_ASSIGNED_STATUS = 'PROJECT_WORK_VIEW_ASSIGNED_STATUS',
  PROJECT_WORK_VIEW_INPROGRESS_STATUS = 'PROJECT_WORK_VIEW_INPROGRESS_STATUS',
  PROJECT_WORK_VIEW_REJECT_STATUS = 'PROJECT_WORK_VIEW_REJECT_STATUS',
  PROJECT_WORK_VIEW_COMPLETE_STATUS = 'PROJECT_WORK_VIEW_COMPLETE_STATUS',
  PROJECT_WORK_ADD = 'PROJECT_WORK_ADD',
  PROJECT_WORK_ASSIGN_MANUALLY = 'PROJECT_WORK_ASSIGN_MANUALLY',
  PROJECT_WORK_MODIFY = 'PROJECT_WORK_MODIFY',
  PROJECT_WORK_CUSTOM_REPORT = 'project-work-custom-report',
  CUST_PROJECT_WORK_VIEW_ALL_RECORDS = 'CUST_PROJECT_WORK_VIEW_ALL_RECORDS',
  CUST_PROJECT_WORK_VIEW_MY_RECORDS = 'CUST_PROJECT_WORK_VIEW_MY_RECORDS',
  EQUIPMENT_PM_CUSTOM_REPORT = 'equipment-pm-custom-report',
  CUST_EQUIPMENT_PM_VIEW_ALL_RECORDS = 'CUST_EQUIPMENT_PM_VIEW_ALL_RECORDS',
  CUST_EQUIPMENT_PM_VIEW_MY_RECORDS = 'CUST_EQUIPMENT_PM_VIEW_MY_RECORDS',
  LOCATION_TEMPLATE_DELETE = 'LOCATION_TEMPLATE_DELETE',
  CALENDER_SCHEDULES_CUSTOM_REPORT = 'calender-schedules-custom-report',
  TRAX_IQ = 'trax-iq',
}
