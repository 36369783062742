<div class="trax-table">
    <div class="table-container mat-elevation-z8">
        <mat-paginator [pageSizeOptions]="[25, 100, 200, 400]" showFirstLastButtons aria-label="Select page"
            [pageSize]="25" [length]="tableDataSource?.data?.length">
        </mat-paginator>

        <!-- Column Filters -->
        <ng-container *ngIf="filterForm">
            <form [formGroup]="filterForm" class="column-filters">
                <ng-container *ngFor="let filter of columnFilters">
                    <mat-form-field appearance="outline" class="filter-field">
                        <mat-label>{{getColumnHeader(filter.column)}}</mat-label>
                        <mat-select [formControlName]="filter.control" multiple #select="matSelect">
                            <mat-select-trigger>
                                {{select.triggerValue?.length ? select.triggerValue[0] : ''}}
                                <span *ngIf="select.triggerValue?.length > 1">
                                    (+{{select.triggerValue.length - 1}} others)
                                </span>
                            </mat-select-trigger>

                            <div class="select-search-box">
                                <mat-form-field appearance="outline" class="search-field">
                                    <input matInput [formControl]="filter.searchControl" placeholder="Search..."
                                        (keydown.Space)="$event.stopPropagation()">
                                </mat-form-field>
                            </div>

                            <mat-option *ngFor="let option of filter.filteredOptions" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <button mat-button color="warn" (click)="clearFilters()" class="clear-filters">
                    Clear Filters
                </button>
            </form>
        </ng-container>

        <!-- Rest of the template remains the same -->
        <div class="table-scroll">
            <table mat-table [dataSource]="dataSource$ | async" class="mat-elevation-z8 w-100 hover-highlight"
                #exporter="matTableExporter" matTableExporter matSort [matSortActive]="firstField"
                matSortDirection="asc" matSortDisableClear>

                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{getColumnHeader(column)}}
                    </th>
                    <td mat-cell *matCellDef="let data">
                        {{formatValue(data[column])}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="export-buttons">
            <button mat-raised-button
                (click)="exporter.exportTable('xlsx', {fileName:'test', sheet: 'sheet_name', Props: {Author: 'Talha'}})">
                Excel
            </button>
            <button mat-raised-button (click)="exporter.exportTable('csv')">Csv</button>
            <button mat-raised-button (click)="exporter.exportTable('txt')">Txt</button>
        </div>
    </div>
</div>