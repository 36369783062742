import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  restroomDropdownSettings = {
    singleSelection: false,
    idField: 'LocationId',
    textField: 'LocationName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  corporationDropdownSettings = {
    singleSelection: false,
    idField: 'CorporationId',
    textField: 'CorporationName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  venueDropdownSettings = {
    singleSelection: false,
    idField: 'VenueId',
    textField: 'VenueName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  buildingDropdownSettings = {
    singleSelection: false,
    idField: 'BuildingId',
    textField: 'BuildingName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  zoneDropdownSettings = {
    singleSelection: false,
    idField: 'ZoneId',
    textField: 'ZoneName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  levelDropdownSettings = {
    singleSelection: false,
    idField: 'LevelId',
    textField: 'LevelName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  locationDropdownSettings = {
    singleSelection: false,
    idField: 'LocationId',
    textField: 'LocationName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Sorry no data found!',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  pageSizes = [
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 200, name: '200' },
    { id: 400, name: '400' },
  ];
}
