import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshDashBoardService {
  private refreshSubject = new Subject<void>();

  get refresh$() {
    return this.refreshSubject.asObservable();
  }

  triggerRefresh(): void {
    this.refreshSubject.next();
  }
}
