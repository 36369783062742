import { environment as higherEnvironment } from './environment.flagship.qa';
export const environment = {
  ...higherEnvironment,
  server: 'https://api.dev.traxinsights.app/TraxDevAPI/',
  touchlessFeedbackAppBaseURL: 'https://touchless.dev.flagship.traxinsights.app/#/',
  cleaningAlertRequestURL: 'https://ca.dev.flagship.traxinsights.app/#/',
  projectTitle: 'Flagship Intuition',
  faviconUrl: 'assets/flagship/flagship_fav_icon.ico',
  authServer: 'https://dev-app-internalapi-trax-eastus-01.azurewebsites.net/',
};
