import { RouterModule, Routes } from '@angular/router';
import { CleaningAlertLogsListComponent } from './components/cleaning-alert-logs-list/cleaning-alert-logs-list.component';
import { EditCleaningAlertLogComponent } from './components/edit-cleaning-alert-log/edit-cleaning-alert-log.component';

const routes: Routes = [
  {
    path: 'list',
    component: CleaningAlertLogsListComponent,
  },
  {
    path: ':id',
    component: EditCleaningAlertLogComponent,
  },
];

export const WorkorderRoutes = RouterModule.forChild(routes);
