import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Userpilot } from 'userpilot';
import { Configuration } from './app.constants';
import { AuthState } from './core/services/auth.service';
import { selectUserData } from './root-store/user-data/user-selector';
import { LoaderService } from './shared/directives/loader.service';
import { RefreshDashBoardService } from './shared/services/RefreshDashBorad.service';

@UntilDestroy()
@Component({
  selector: 'trax-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss', '../style/app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  showLoader: boolean;
  Islogin: boolean;
  private subscription: Subscription;

  constructor(
    private authState: AuthState,
    private loaderService: LoaderService,
    public cdRef: ChangeDetectorRef,
    public configuration: Configuration,
    private readonly store: Store,
    private refreshService: RefreshDashBoardService,
  ) {
    this.authState.isAuthenticated.subscribe((value) => {
      this.Islogin = value;
    });
  }

  ngOnInit() {
    this.loadData();
    this.subscription = this.refreshService.refresh$.subscribe(() => {
      this.loadData();
    });
  }
  loadData() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.store
      .select(selectUserData)
      .pipe(untilDestroyed(this))
      .subscribe((_value) => {
        if (_value) {
          this.authState.userInfoChanged.next('userInfoChanged');
        }
      });

    this.configuration.CorporationName = this.authState.getStoredCorporationName();
    this.configuration.VenueName = this.authState.getStoredVenueName();

    //Initialize Userpilot
    Userpilot.initialize('NX-15ad5535');

    document.title = document.title.replace(document.title, environment.projectTitle);
    const a: any = document.getElementById('faviconId');
    a.href = environment.faviconUrl;
  }
}
