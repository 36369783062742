
<div class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12 ca-attachment" id="caAttachmentPopUp">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h3 style="margin: 0 auto">Create Cleaning Alert</h3>
      <button type="button" class="close" data-dismiss="modal" (click)="closeCAAttachmentPopUp()">&times;</button>
    </div>

    <div class="modal-body">
      <div class="row" *ngIf="isCAAttachmentUploadAllow && showComments">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <label>Do you want to add pictures or videos?</label>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label class="custom-file-selector-label pull-left">
            + Add Attachments
            <input type="file" id="custom-file-selector-input" #caFileInput name="file-selector"
              (change)="fileChange($event)" accept=".jpg, .jpeg, .png, .mp4, .mov, .m4v" />
          </label>
        </div>
      </div>

      <div class="row" *ngIf="!isCAAttachmentUploadAllow || !showComments">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4>Do you really want to create alert?</h4>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 form-group" style="display: flex"
        *ngIf="caAttachments != null && caAttachments.length > 0">
        <div class="ca-attachment-box" *ngFor="let attach of caAttachments"
          style="width: 175px; height: 150px; padding: 5px">
          <button id="templateReset_{{ attach.rowid }}" class="btn-primary btn btn-xs button-text" type="button"
            *ngIf="attach.attachmentImage" (click)="removeAttachment(attach.rowid)" style="z-index: 1">
            <i class="fa fa-times"></i> Remove
          </button>

          <a *ngIf="
              attach.attachmentImageType == 'mp4' ||
              attach.attachmentImageType == 'mov' ||
              attach.attachmentImageType == 'm4v'
            " class="fa fa-video-camera" style="
              position: absolute;
              display: block;
              color: black;
              padding: 55px 0px 0px 65px;
              font-size: xx-large;
            "></a>
          <img id="file_{{ attach.rowid }}"
            [src]="attach.attachmentImage != null ? attach.attachmentImage : DefaultImage" class="user-profile" />
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 toggle-wrapper" *ngIf="configuredTasks.length">
        <mat-slide-toggle [checked]="isFullMode" (change)="toggle($event.checked)">
          <span>Full</span>
        </mat-slide-toggle>
      </div>
      <!-- Include task selection -->
      <app-task-selection *ngIf="caAttachmentPopUp" [class.hide]="isFullMode" [taskParams]="taskParams" [selectAllAction]="true"
        (selectedTasks)="onTaskSelection($event)" [tasksList]="tasksList"></app-task-selection>

      <div class="col-lg-12 col-md-12 col-sm-12 form-group" *ngIf="isCAAttachmentUploadAllow && showComments">
        <label style="float: left">Comments</label>
        <textarea class="form-control" rows="4" maxlength="500" id="CreatorComments" placeholder="Creator Comments"
          name="CreatorComments" [(ngModel)]="CreatorComments"></textarea>
      </div>

      <div class="modal-footer col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <input type="button" value="Create Alert" class="btn btn-type btn-save btn-left"
          (click)="createWorkOrder()" />
        <input type="button" value="Cancel" class="btn btn-type btn-cancel btn-right"
          (click)="closeCAAttachmentPopUp()" />
      </div>
    </div>
  </div>
</div>
</div>
