import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from '../core/core.module';
import { ClickOutsideDirective } from '../core/directives/click-outside.directive';
import { AlertComponent } from './components/alertmodal/alertmodal.component';
import { ConfirmComponent } from './components/confirmmodal/confirmmodal.component';
import { CreateCleaningAlertComponent } from './components/create-cleaning-alert/create-cleaning-alert.component';
import { OpenCleaningAlertCountComponent } from './components/open-cleaning-alert-count/open-cleaning-alert-count/open-cleaning-alert-count.component';
import { TaskSelectionComponent } from './components/task-selection/task-selection.component';
import { CustomFooterComponent } from './components/customfooter/customfooter.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    NgxPaginationModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  declarations: [
    CustomFooterComponent,
    AlertComponent,
    ConfirmComponent,
    TaskSelectionComponent,
    CreateCleaningAlertComponent,
    ClickOutsideDirective,
    OpenCleaningAlertCountComponent,
  ],
  exports: [
    CustomFooterComponent,
    TaskSelectionComponent,
    CreateCleaningAlertComponent,
    MatDialogModule,
    CoreModule,
    ClickOutsideDirective,
    OpenCleaningAlertCountComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
