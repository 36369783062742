import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class CleaningAlertService {
  constructor(
    private readonly http: HttpClient,
    private readonly configuration: Configuration,
  ) {}

  /**
   * Method to get count of open cleaning alerts
   *
   * @param locationId location id
   * @returns count
   */
  public getOpenCleaningAlerts(locationId: number): Observable<number> {
    const _url: string = this.configuration.Server + 'venue/location/get-open-cleaning-alerts-count';
    // Create HttpParams to append the locationId query parameter
    const params = new HttpParams().set('locationId', locationId.toString());
    return this.http.get<number>(_url, { params });
  }
}
