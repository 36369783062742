<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search">
    <form>
        <input type="text" name="searchString" class="form-control search-radius searchBoxRadius" placeholder="Search"
            [(ngModel)]="searchString" autocomplete="off" (keyup)="onKeyUp($event)" />
        <div class="input-group-btn">
            <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="doSearch(searchString)">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>
</div>