import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAlertAttachments, ICreateAlertRequest } from 'src/app/core/models/common.model';
import { ITaskParams, ITasks } from 'src/app/core/models/task.model';
import { LoaderService } from '../../directives/loader.service';
import { CompressImageService } from '../utilityservices/compressimageservice';
import { UtilitiesService } from '../utilityservices/utilityservice';

// Opens Create cleaning alert popup
// User can select the Full or requested mode and select tasks based on the selected mode.
// User can upload attachments and view then if they have the permission to attach uploads
// On save a new cleaning alert is created.

@Component({
  selector: 'app-create-cleaning-alert',
  templateUrl: './create-cleaning-alert.component.html',
  styleUrls: ['./create-cleaning-alert.component.scss'],
})
export class CreateCleaningAlertComponent implements OnInit {
  @Input() caAttachmentPopUp: boolean;
  @Input() taskParams: ITaskParams;
  @Input() showComments = true;
  @Input() tasksList;
  @Output() closeAttachmentPopUp = new EventEmitter();
  @Output() createCleaningAlert = new EventEmitter();
  @Output() selectedTasks = new EventEmitter();
  @Output() selectedAttachments = new EventEmitter<ICreateAlertRequest>();
  @Output() modeChange = new EventEmitter();
  isCAAttachmentUploadAllow: boolean;
  caAttachmentImgSrc: any;
  caAttachmentFile: any;
  caAttachments: IAlertAttachments[] = [];
  caAttachmentFileType: any;
  caAttachFile: any;
  caCompressImage: any;
  file: Blob;
  attachmentRowId: any;
  caFileInputVariable: any;
  attachmentIndex: number;
  isFullMode = true;
  configuredTasks: ITasks[] = [];
  CreatorComments: string;

  constructor(
    private readonly utilityService: UtilitiesService,
    private readonly loaderService: LoaderService,
    private compressImage: CompressImageService,
  ) {}

  ngOnInit(): void {
    this.isCAAttachmentUploadAllow = this.utilityService.checkVenueFeatureConfiguration('ALLOW_ATTACHMENTS_FROM_WEB');
  }

  /**
   * Method to close the create alert popup
   */
  closeCAAttachmentPopUp() {
    this.toggle(true);
    this.closeAttachmentPopUp.emit();
  }

  /**
   * Method to be called on file change
   */
  public fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      this.loaderService.display(true);
      this.caAttachmentImgSrc = null;
      this.caAttachmentFile = '';
      this.caCompressImage = null;

      if (this.caAttachments.length != null && this.caAttachments.length >= 5) {
        this.onFileError(event, 'You have reached the maximum limit to upload attachments.');
      }

      const uploadedFileName = this.utilityService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.onFileError(event, this.utilityService.fileUploadErrorMessage);
      }

      this.checkFileSizeValidation(event);
    }
  }

  /**
   * Method to be called on file read
   */
  onFileRead(attachmentFile: any, attachmentType: string, readerEvt: any) {
    if (attachmentType === 'image') {
      this.compressImg(attachmentFile);
    } else if (attachmentType === 'video') {
      this.caAttachFile = attachmentFile;
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.caAttachFile);
      this.caAttachmentFile = attachmentFile;
    }
  }

  /**
   * Compress image method
   */
  async compressImg(img: any): Promise<void> {
    await this.compressImageMethod(img);
  }

  public compressImageMethod(img: any) {
    this.compressImage.compress(img).subscribe(
      (response) => {
        this.loaderService.display(false);
        this.caCompressImage = response;
        const imageFileList: FileList = this.caCompressImage;
        this.caAttachFile = imageFileList;
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.caAttachFile);
        this.caAttachmentFile = this.caAttachFile;
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'Image compression service Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  /**
   * Method to set the attachments
   */
  public _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.caAttachmentImgSrc = 'data:image/jpeg;base64,' + btoa(binaryString);

    this.attachmentRowId = this.caAttachments.length;
    this.attachmentRowId = this.attachmentRowId + 1;

    const attach = {
      rowid: this.attachmentRowId,
      attachmentImage: this.caAttachmentImgSrc,
      attachmentImageFile: this.caAttachmentFile,
      attachmentImageType: this.caAttachmentFileType,
      IsActive: 1,
    };
    this.caAttachments.push(attach);
    this.caAttachFile = null;
  }

  /**
   * Method to remove the attachment
   */
  public removeAttachment(rowId: number) {
    if (this.caAttachments != null && this.caAttachments.length > 0) {
      for (let i = 0; i < this.caAttachments.length; i++) {
        if (this.caAttachments[i].rowid == rowId) {
          this.caAttachments[i].IsActive = 0;
          this.attachmentIndex = i;
          if (this.caAttachments[i].IsActive == 0) {
            this.caAttachments.splice(this.attachmentIndex, 1);
            i = i - 1;
          }
        }
      }

      this.caAttachments.forEach((item, index) => {
        item.rowid = null;
        item.rowid = index + 1;
      });
    }
  }

  /**
   * Method to emit the event on create work order click
   */
  createWorkOrder() {
    this.selectedAttachments.emit({ attachments: this.caAttachments, creatorComments: this.CreatorComments });
    this.createCleaningAlert.emit();
  }

  /**
   * Method to set the selected tasks
   */
  onTaskSelection($event: ITasks[]) {
    this.configuredTasks = $event;
    this.selectedTasks.emit($event);
  }

  /**
   * Method to be called if file validation fails
   */
  onFileError(event, message) {
    this.utilityService.mdmodal('Cleaning Alert Attachment', message);
    event.target.value = '';
    this.loaderService.display(false);
    return;
  }

  /**
   * Method to check the file size/file type validation
   */
  checkFileSizeValidation(event) {
    const extention = event.target.files[0].name.split('.');
    if (extention != null && extention.length > 0) {
      const ext = extention[extention.length - 1].toLowerCase();
      this.caAttachmentFileType = ext;
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'mp4' || ext == 'm4v' || ext == 'mov') {
        if (event.target.files && event.target.files[0]) {
          if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
            const filesize = event.target.files[0].size / Math.pow(1024, 2);
            if (filesize > this.utilityService.fileUploadSize) {
              this.onFileError(event, 'Picture should not be more than ' + this.utilityService.fileUploadSize + ' MB.');
            }

            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this.onFileRead.bind(this, event.target.files[0], 'image');
              reader.readAsBinaryString(this.file);
            }
          } else if (ext === 'mp4' || ext === 'm4v' || ext === 'mov') {
            const videoFileList: FileList = event.target.files;
            const filesize = event.target.files[0].size / Math.pow(1024, 2);

            if (filesize > 50) {
              this.onFileError(event, 'Video should not be more than 50 MB.');
            }

            this.loaderService.display(false);
            this.caAttachFile = videoFileList[0];
            const reader = new FileReader();
            reader.onload = this.onFileRead.bind(this, videoFileList[0], 'video');
            reader.readAsBinaryString(this.caAttachFile);
            this.caAttachmentFile = event.target.files[0];
          }
        }
      } else {
        this.onFileError(event, 'Please upload file of type .jpg, .jpeg, .png, .mp4, .m4v, .mov.');
      }
    } else {
      this.loaderService.display(false);
    }
  }

  /**
   * Method to toggle the full mode
   */
  toggle(value: boolean) {
    this.isFullMode = value;
    this.modeChange.emit(this.isFullMode);
  }
}
