import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { User } from 'src/app/core/models/user.model';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CountryService } from '../../country/components/country.http.service';
import { CountryModule } from '../../country/country.module';
import { StateModule } from '../state.module';
import { StateService } from './state.http.service';

@Component({
  selector: 'state-component',
  templateUrl: 'state.component.html',
  providers: [StateService, CountryService, UtilitiesService],
})
export class StateComponent implements OnInit {
  TraxPermission = TraxPermission;
  currentUser: User;

  public countries: CountryModule[] = [];

  public states: StateModule[] = [];
  public state: StateModule = new StateModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];

  searchString = '';
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState: StateMaintain = {
    pageName: 'stateForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  constructor(
    private stateService: StateService,
    private utilitiesService: UtilitiesService,
    private countryService: CountryService,
    private loaderService: LoaderService,
    private authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.state.Country = new CountryModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllStates(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllStates(this.searchString, this.page, this.size, this.viewType);
    }
  }

  /// this funcation used to get result as per page size change
  public StatePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }

  public setAddForm() {
    this.getAllCountries('', -1, -1);
    this.searchString = '';
    this.states = null;
    this.count = 0;
    this.state = new StateModule();
    this.state.Country = new CountryModule();
  }

  public setUpdateForm() {
    this.searchString = '';
    this.states = null;
    this.count = 0;
    this.getAllCountries('', -1, -1);
  }

  public clearFormData(stateForm: NgForm) {
    this.searchString = '';
    stateForm.reset();
    this.state.Country = new CountryModule();
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }

  public statesSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllStates(searchString, 1, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.states != null && this.states.length > 0;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllCountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', genericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;

        if (!this.state.StateId) {
          const obj = this.countries.filter(
            (c) => c.CountryName == 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj && obj[0]) {
            this.state.Country.CountryId = obj[0].CountryId;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'stateService getAllCountries Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllStates(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      genericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.stateService.GetStateList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.states = data;
        if (this.states.length < 0 && this.states[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'stateService getAllstates Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.states[0] ? this.states[0].Count : 0),
    );
  }

  public getStateById(stateId: number) {
    this.loaderService.display(true);
    const CPstate = new StateModule();
    CPstate.StateCPId = this.authState.AESEncryptText(stateId);

    this.stateService.GetSingle(CPstate).subscribe(
      (data) => {
        this.state = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'stateService getStateById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public addState(stateForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (stateForm.status == 'INVALID' && stateForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('State', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.state.StateName = this.utilitiesService.removeInnerSpaces(this.state.StateName);
    if (this.state.StateName == null || this.state.StateName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.stateService.Add(this.utilitiesService.stripScript(this.state)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('State', data.Message);
        this.clearFormData(stateForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('stateService addState Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateState(stateForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (stateForm.status == 'INVALID' && stateForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('State', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.state.StateName = this.utilitiesService.removeInnerSpaces(this.state.StateName);
    if (this.state.StateName == null || this.state.StateName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.state.IsActive = this.state.IsActive ? 1 : 0;

    this.stateService.Update(this.utilitiesService.stripScript(this.state)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('State', data.Message);
        this.clearFormData(stateForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'stateService updateState Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllStates(this.searchString, this.page, this.size, this.viewType);
  }
}
