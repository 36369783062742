import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TraxHeaderSearchComponent } from './trax-header-search.component';

@NgModule({
  declarations: [TraxHeaderSearchComponent],
  imports: [CommonModule, FormsModule],
  exports: [TraxHeaderSearchComponent],
})
export class TraxHeaderSearchModule {}
