import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from 'src/app/features/home/home.module';
import { SharedModule } from '../../shared.module';
import { NavigationComponent } from './navigation.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, HomeModule, RouterModule, SharedModule],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule {}
