import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { User } from 'src/app/core/models/user.model';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CountryService } from '../../country/components/country.http.service';
import { CountryModule } from '../../country/country.module';
import { StateService } from '../../state/components/state.http.service';
import { StateModule } from '../../state/state.module';
import { CityModule } from '../city.module';
import { CityService } from './city.http.service';

@Component({
  selector: 'city-component',
  templateUrl: 'city.component.html',
  providers: [CountryService, StateService, CityService, UtilitiesService],
})
export class CityComponent implements OnInit {
  TraxPermission = TraxPermission;
  currentUser: User;

  public countries: CountryModule[] = [];
  public states: StateModule[] = [];

  public cities: CityModule[] = [];
  public city: CityModule = new CityModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];

  searchString = '';
  searchTerm: string;
  stateDisabled = true;
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState: StateMaintain = { pageName: 'cityForm', page: this.page, size: this.size, viewType: this.viewType };

  constructor(
    private utilitiesService: UtilitiesService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.city.Country = new CountryModule();
    this.city.State = new StateModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.getAllcountries('', -1, -1);
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllCities(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllCities(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.states = [];
    this.stateDisabled = true;
    this.searchString = '';
    this.cities = null;
    this.count = 0;
    this.city = new CityModule();
    this.city.Country = new CountryModule();
    this.city.State = new StateModule();
    this.getAllcountries('', -1, -1);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.cities = null;
    this.count = 0;
    this.getAllcountries('', -1, -1);
  }

  public clearFormData(cityForm: NgForm) {
    cityForm.reset();
    this.searchString = '';
    this.stateDisabled = true;
    this.city.Country = new CountryModule();
    this.city.State = new StateModule();
    this.getAllCities('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllCities('', this.page, this.size, this.viewType);
  }

  public citiesSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCities(searchString, 1, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCities(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public CityPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCities(this.searchString, this.page, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.cities !== null && this.cities.length > 0;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCities(this.searchString, this.page, this.size, this.viewType);
  }

  public addCity(cityForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (cityForm.status == 'INVALID' && cityForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('City', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.city.CityName = this.utilitiesService.removeInnerSpaces(this.city.CityName);
    if (this.city.CityName === null || this.city.CityName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.cityService.Add(this.utilitiesService.stripScript(this.city)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('City', data.Message);
        this.clearFormData(cityForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('cityService addCity Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateCity(cityForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (cityForm.status == 'INVALID' && cityForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('City', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.city.CityName = this.utilitiesService.removeInnerSpaces(this.city.CityName);
    if (this.city.CityName === null || this.city.CityName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.city.IsActive = this.city.IsActive ? 1 : 0;

    this.cityService.Update(this.utilitiesService.stripScript(this.city)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('City', data.Message);
        this.clearFormData(cityForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('cityService updateCity Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getAllCities(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      state: {},
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.cityService.GetCitiesList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.cities = data;
        if (this.cities.length < 0 && this.cities[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'cityService getAllcities Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.cities[0] ? this.cities[0].Count : 0),
    );
  }

  public getCityById(cityId: number) {
    this.loaderService.display(true);
    const CPcity = new CityModule();
    CPcity.CityCPId = this.authState.AESEncryptText(cityId);

    this.cityService.GetSingle(CPcity).subscribe(
      (data) => {
        this.city = data;
        this.loaderService.display(false);
        if (this.city.Country.CountryId) {
          this.getAllstatesByCountry(this.city.Country.CountryId);
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log('cityService getCityById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
      () => this.setUpdateForm(),
    );
  }

  public getAllcountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;
        if (!this.city.CityId) {
          const obj = this.countries.filter(
            (c) => c.CountryName === 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj[0]) {
            this.city.Country.CountryId = obj[0].CountryId;
            this.loaderService.display(false);
            this.getAllstatesByCountry(this.city.Country.CountryId);
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'cityService getAllcountries Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public countryChange($event: any) {
    this.city.Country.CountryId = $event;
    if (this.city.Country.CountryId) {
      this.states = [];
      this.city.State.StateId = null;
      this.getAllstatesByCountry(this.city.Country.CountryId);
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.city.State.StateId = null;
    }
  }

  public getAllstatesByCountry(countryId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      country: { countryId },
    };

    if (countryId) {
      this.stateService.GetStateDropdown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.states = data;
          this.stateDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'stateService getAllstates Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else {
      this.states = [];
      this.stateDisabled = true;
    }
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllCities(this.searchString, this.page, this.size, this.viewType);
  }
}
