<div class="task-wrapper" *ngIf="tasks.length">
  <div class="task-label" *ngIf="selectAllAction">
    <mat-checkbox
      [(ngModel)]="isSelectAll"
      (change)="toggleSelectAll($event.checked)"
      [ngModelOptions]="{ standalone: true }"
      >All</mat-checkbox
    >
  </div>
  <div class="task-container">
    <div
      class="item"
      *ngFor="let task of tasks; let i = index"
      [class.border-none]="task.length % 2 === 0 && i === task.length - 2"
    >
      <mat-checkbox
        [(ngModel)]="task.IsActive"
        (change)="toggle()"
        [ngModelOptions]="{ standalone: true }"
      ></mat-checkbox>
      <span [attr.title]="task.LocationTaskName">{{ task.LocationTaskName }}</span>
    </div>
  </div>
</div>
