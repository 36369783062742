import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';

// This pipe returns whether the user has the venue configuration.

@Pipe({
  name: 'checkVenueConfig',
  standalone: true,
})
export class CheckVenueConfigPipe implements PipeTransform {
  constructor(private readonly utilitiesService: UtilitiesService) {}

  transform(configuration: string): boolean {
    return this.utilitiesService.checkVenueFeatureConfiguration(configuration);
  }
}
