import { NgModule } from '@angular/core';
import { CanAccessPipe } from './pipes/can-access.pipe';
import { CheckVenueConfigPipe } from './pipes/check-venue-config.pipe';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [CheckVenueConfigPipe],
  declarations: [CanAccessPipe, SafePipe],
  exports: [CanAccessPipe, SafePipe],
})
export class CoreModule {}
