import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { User } from 'src/app/core/models/user.model';
import {
  ICleaningAlertLogs,
  IInspectionBeacon,
  IQCBeacon,
  IQCMaster,
  IWorkorderAndQCLogAndHistoryDetails,
  IWorkorderDetails,
  IWorkorderDetailsBeacon,
  IWorkorderDetailsInventory,
  IWorkorderDetailsStatus,
  IWorkorderDetailsTaskComment,
  IWorkorderQCImages,
  IWorkOrderStatus,
} from 'src/app/core/models/work-order.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { IAdditionalCleaningAlerts, WorkOrderStatus, WoStatus } from '../../models/cleaning-alert-log.model';
import { WorkorderService } from '../workorder.http.service';

// The cleaning alerts logs edit screen in displayed when user clicks on an individual record in Cleaning Alerts Logs list
// User can edit the status of the cleaning alert and also assign user to a created cleaning alert
// Only users with view and edit permissions can edit a cleaning alert log.
// User cannot change the status to QC related statuses from the edit screen
// User can view the log history and inspections details in the edit screen
// On successful save, the user navigates back to the listing screen.

@Component({
  selector: 'app-edit-cleaning-alert-log',
  templateUrl: './edit-cleaning-alert-log.component.html',
  styleUrls: ['./edit-cleaning-alert-log.component.scss'],
})
export class EditCleaningAlertLogComponent implements OnInit {
  TraxPermission = TraxPermission;
  isReqComplete: boolean;
  cleaningAlertId: number;
  cleaningAlert: ICleaningAlertLogs;
  isStatusComplete: boolean;
  assignedToDisabled = true;
  isReadOnlyField: boolean;
  isBadgeEnable: boolean;
  storedStatus: string;
  inspectionDetailsBeacons: IInspectionBeacon[] = [];
  cleaningAlertRejectQCModal = false;
  isWorkorderDetails: boolean;
  assignedToUsers: User[];
  workorderStatus: IWorkOrderStatus[] = [];
  inspectionHistory: boolean;
  workorderHistoryDetails: IWorkorderAndQCLogAndHistoryDetails[];
  visibleIndex: number;
  isBadge: boolean;
  workorderAndQCLogAndHistoryDetails: IWorkorderAndQCLogAndHistoryDetails;
  workorderDetails: IWorkorderDetails;
  qcDetails: IQCMaster;
  workorderQCImages: IWorkorderQCImages[] = [];
  isQCLogShow: boolean;
  workorderDetailsBeacons: IWorkorderDetailsBeacon[] = [];
  workorderDetailsStatus: IWorkorderDetailsStatus[] = [];
  workorderDetailsBadges: IWorkorderDetailsBeacon[] = [];
  workorderDetailsTasks: IWorkorderDetailsTaskComment;
  workorderDetailsInventories: IWorkorderDetailsInventory[] = [];
  isTaskComment: boolean;
  isQCTaskComment: boolean;
  isQCFeedback: boolean;
  qcDetailsBeacons: IQCBeacon[] = [];
  totalCleanTime: string;
  currentUser: User;
  today: string;
  reAssignErrorMsg: string;
  reAssignModal: boolean;
  qcAttachmentImg: string;
  InspectionImg: string;
  CreatorsAttachmentImg: string;
  CompletionDate: string = null;
  additionalCleaningAlerts: IAdditionalCleaningAlerts[] = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly authState: AuthState,
    private readonly WOService: WorkorderService,
    private readonly utilitiesService: UtilitiesService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authState.getCurrentUser();
    this.route.params.subscribe((data) => {
      this.cleaningAlertId = data.id;
      this.getLogDetailsById();
    });
    this.isBadge = this.authState.getStoredBadge() === 1;
  }

  /**
   * Method to display the Inspection History on clicking 'Inspection History' button
   */
  getInspectionDetails() {
    this.inspectionHistory = true;
    this.isWorkorderDetails = false;
  }

  /**
   * Method to get the list of statuses populated in edit cleaning alert screen, status dropdown
   */
  getWorkOrderStatus(statusId: number) {
    const workOrder = {
      StatusCPId: this.authState.AESEncryptText(statusId),
      CPtype: this.authState.AESEncryptText('C'),
    };
    this.WOService.GetWorkorderStatus(workOrder).subscribe((data) => (this.workorderStatus = data));
  }

  /**
   * Method to navigate to cleaning alert log list
   */
  navigateToLogsList() {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  /**
   * Method to get the individual cleaning alert log details and set fields disabled condition
   */
  getLogDetailsById() {
    this.loaderService.display(true);
    const WorkorderCPId: string = this.authState.AESEncryptText(this.cleaningAlertId);
    this.WOService.GetSingle({ WorkorderCPId }).subscribe((data) => {
      this.cleaningAlert = data;
      this.additionalCleaningAlerts = data.AdditionalAlerts;
      const statuses = [
        WorkOrderStatus.COMPLETE,
        WorkOrderStatus.CANCEL,
        WorkOrderStatus.QC_REJECTED,
        WorkOrderStatus.AWAITING_QC,
        WorkOrderStatus.QC_IN_PROGRESS,
        WorkOrderStatus.QC_ASSIGNED,
        WorkOrderStatus.QC_APPROVED,
        WorkOrderStatus.CLOSED_NOT_COMPLETE,
      ];

      // Set the field statuses
      this.storedStatus = this.cleaningAlert.WorkorderStatus.Status;
      const isStatusIncluded = statuses.includes(this.cleaningAlert.WorkorderStatus.Status);
      this.isBadgeEnable = this.isBadge || isStatusIncluded;
      const value =
        isStatusIncluded || this.cleaningAlert.WorkorderStatus.Status === WorkOrderStatus.OPEN || this.isBadge;
      this.isStatusComplete = value;
      this.assignedToDisabled = value;

      if (this.cleaningAlert.Inspection?.InspectionBeacons) {
        this.inspectionDetailsBeacons = this.getInspectionBeaconValues(this.cleaningAlert.Inspection.InspectionBeacons);
      }
      if (this.cleaningAlert.Inspection?.OverallRating) {
        this.cleaningAlert.Inspection.OverallRating = this.cleaningAlert.Inspection.OverallRating.toLowerCase();
      }

      this.loaderService.display(false);
      if (this.cleaningAlert.Location.Zone.ZoneId) {
        this.getAssignedToUsers(this.cleaningAlert.Location.Zone.ZoneId, this.cleaningAlert.WorkorderId);
      }

      if (this.cleaningAlert.WorkorderStatus.StatusId) {
        this.getWorkOrderStatus(this.cleaningAlert.WorkorderStatus.StatusId);
      }

      if (
        this.cleaningAlert.Building?.Venue &&
        this.cleaningAlert.WorkorderStatus.Status !== WorkOrderStatus.COMPLETE
      ) {
        this.cleaningAlert.ModifiedOn = this.utilitiesService.LocaltoVenueTime(
          this.cleaningAlert.Building.Venue.Timediff,
        );
      }
      this.loaderService.display(false);
    });
  }

  /**
   * Method to get the inspection beacon values formatted
   */
  getInspectionBeaconValues(beaconHistory: IInspectionBeacon[]) {
    const nameObjects = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    const that = this;
    const newBeaconHistoryArray: IInspectionBeacon[] = [];
    if (beaconHistory.length > 0) {
      beaconHistory.forEach((item, idx, array) => {
        let nameObj = nameObjects[item.Beacon.BeaconName];
        if (nameObj != lastArrayName && lastArrayName != null) {
          timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntryObject = that.createNewInspectionObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );
          lastArrayName.push(lastEntryObject);

          newBeaconHistoryArray.push(lastEntryObject);
        }

        if (!nameObj) {
          nameObj = nameObjects[item.Beacon.BeaconName] = [];
          firstintime = item.OptIn;
        }

        nameObj.push(item);
        newBeaconHistoryArray.push(item);

        if (item.OptOut != null && item.OptOut != 'null') {
          lastouttime = item.OptOut;
        }

        if (array.length - 1 == 0) {
          lastArrayName = nameObj;
          beaconname = item.Beacon.BeaconName;
          batteryLevel = item.BatteryLevel;
        }

        if (idx == array.length - 1) {
          timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntry = that.createNewInspectionObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );

          lastArrayName.push(lastEntry);

          newBeaconHistoryArray.push(lastEntry);
        }
        lastArrayName = nameObj;
        beaconname = item.Beacon.BeaconName;
        batteryLevel = item.BatteryLevel;
      });
    }
    return newBeaconHistoryArray;
  }

  /**
   * Method to create inspection object properties
   */
  createNewInspectionObjectProperties(
    name: string,
    OptIn: string,
    OptOut: string,
    BatteryLevel: number,
    timeElapsed: string,
  ) {
    const beaconDetails: IInspectionBeacon = {
      OptIn,
      OptOut,
      BatteryLevel,
      timeElapsed,
      Beacon: {
        BeaconName: name,
      },
    };
    return beaconDetails;
  }

  /**
   * Method to get the list of users
   */
  getAssignedToUsers(zoneId: number, workorderId: number) {
    this.loaderService.display(true);
    const workOrder = {
      WorkorderCPId: this.authState.AESEncryptText(workorderId),
      Location: {
        Zone: {
          ZoneCPId: this.authState.AESEncryptText(zoneId),
        },
      },
    };
    this.WOService.GetAllAssignedToByZone(workOrder).subscribe((data) => {
      if (data.length) {
        data.forEach((user) => (user.FirstName = `${user.FirstName} ${user.LastName}`));
      }
      this.assignedToUsers = data;
      this.loaderService.display(false);
    });
  }

  /**
   * Method to be called on status dropdown change
   */
  statusChanged(statusId: number) {
    const status = this.workorderStatus.find((s) => s.StatusId === statusId).Status;
    this.cleaningAlert.WorkorderStatus = {
      ...this.cleaningAlert.WorkorderStatus,
      StatusId: statusId,
      Status: status,
    };

    if (status === WorkOrderStatus.OPEN || status === WorkOrderStatus.DECLINED) {
      this.cleaningAlert.AssignedTo = {
        FirstName: null,
        LastName: null,
        UserId: null,
      };
      this.assignedToDisabled = true;
    } else {
      this.assignedToDisabled = false;
    }

    if (this.cleaningAlert.WorkorderStatus.Status === WorkOrderStatus.COMPLETE) {
      this.CompletionDate = this.utilitiesService.LocaltoVenueTime(this.cleaningAlert.Building.Venue.Timediff);
    }

    this.isStatusComplete =
      (status === WorkOrderStatus.OPEN || status === WorkOrderStatus.DECLINED || this.isBadge) &&
      !(this.storedStatus === WorkOrderStatus.ASSIGNED && status === WorkOrderStatus.DECLINED);
  }

  /**
   * Method to be clicked when user clicks on 'View Log' button
   */
  getWorkorderDetails(workorderId: number) {
    this.loaderService.display(true);

    const CPworkorder = {
      WorkorderCPId: this.authState.AESEncryptText(workorderId),
    };
    this.WOService.GetWorkorderDetails(CPworkorder).subscribe((data) => {
      this.inspectionHistory = false;
      this.workorderAndQCLogAndHistoryDetails = data;
      this.workorderDetails = this.workorderAndQCLogAndHistoryDetails.WorkorderDetails;
      this.qcDetails = this.workorderAndQCLogAndHistoryDetails.QCDetails;

      if (this.qcDetails?.QCImages) {
        this.workorderQCImages = this.qcDetails.QCImages;
      }

      if (this.workorderDetails.Status.length > 0) {
        this.isQCLogShow = !this.workorderDetails.Status.some(
          (status) => status.StatusId === WoStatus.QC_Rejected || status.StatusId === WoStatus.QC_Approved,
        );
      }

      if (this.workorderDetails) {
        this.isWorkorderDetails = true;
        this.workorderDetailsStatus = this.workorderDetails.Status;
        this.workorderDetailsBeacons = this.getBeaconValues(this.workorderDetails.Beacons);
        this.workorderDetailsBadges = this.getBadgeValues(this.workorderDetails.Badges);

        if (this.workorderDetails.Task) {
          this.workorderDetailsTasks = this.workorderDetails.Task;
        }

        if (this.workorderDetails.Inventory) {
          this.workorderDetailsInventories = this.workorderDetails.Inventory;
        }
        this.isTaskComment = false;
        if (this.workorderDetailsTasks) {
          if (this.workorderDetailsTasks.Comment && this.workorderDetailsTasks.Comment != '') {
            this.isTaskComment = true;
          }
        }
      }
      this.isQCTaskComment = false;
      if (this.qcDetails) {
        if (this.qcDetails.QCTaskComment?.QcComment && this.qcDetails.QCTaskComment?.QcComment != '') {
          this.isQCTaskComment = true;
        }

        if (this.qcDetails.PreQCFeedback && this.qcDetails.PreQCFeedback != '') {
          this.isQCFeedback = true;
        }

        if (this.qcDetails.QCBeacons) {
          this.qcDetailsBeacons = this.getQCBeaconValues(this.qcDetails.QCBeacons);
        }
      }
      this.loaderService.display(false);
    });
  }

  /**
   * get the formatted beacon history to be displayed on View Log button click
   */
  getBeaconValues(beaconHistory: IWorkorderDetailsBeacon[]) {
    const nameArrays = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    const newBeaconHistoryArray: IWorkorderDetailsBeacon[] = [];
    if (beaconHistory?.length > 0) {
      beaconHistory.forEach((item, idx, array) => {
        let nameArray = nameArrays[item.Name];
        if (nameArray != lastArrayName && lastArrayName != null) {
          timeElapse = this.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntryObject = this.createNewObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );
          lastArrayName.push(lastEntryObject);

          newBeaconHistoryArray.push(lastEntryObject);
        }

        if (!nameArray) {
          nameArray = nameArrays[item.Name] = [];
          firstintime = item.Optin;
        }

        nameArray.push(item);
        newBeaconHistoryArray.push(item);

        if (item.Optout && item.Optout != 'null') {
          lastouttime = item.Optout;
        }

        if (array.length - 1 === 0) {
          lastArrayName = nameArray;
          beaconname = item.Name;
          batteryLevel = item.BatteryLevel;
        }

        if (idx == array.length - 1) {
          timeElapse = this.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntry = this.createNewObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );

          lastArrayName.push(lastEntry);

          newBeaconHistoryArray.push(lastEntry);
        }
        lastArrayName = nameArray;
        beaconname = item.Name;
        batteryLevel = item.BatteryLevel;
      });
    }
    return newBeaconHistoryArray;
  }

  /**
   * Create object for beacon value
   */
  createNewObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    return {
      Name: name,
      Optin: optin,
      Optout: optout,
      BatteryLevel: batteryLevel,
      TimeElapsed: timeElapsed,
    };
  }

  /**
   * Get the formatted badge history to be displayed on 'View log button click
   */
  getBadgeValues(badgeHistory: IWorkorderDetailsBeacon[]) {
    const newBadgeHistoryArray: IWorkorderDetailsBeacon[] = [];
    this.totalCleanTime = null;

    if (badgeHistory?.length > 0) {
      let totalSeconds = null;
      badgeHistory.forEach((item) => {
        item.BatteryLevel = null;
        let inDate;
        let outDate;

        if (item.Optin && item.Optout) {
          if (item.Optin.includes('.') == true) {
            item.Optin = item.Optin.split('.')[0];
          }

          if (item.Optout.includes('.') == true) {
            item.Optout = item.Optout.split('.')[0];
          }

          inDate = new Date(item.Optin);
          outDate = new Date(item.Optout);

          item.BatteryLevel = (outDate.getTime() - inDate.getTime()) / 1000;
          totalSeconds = totalSeconds + item.BatteryLevel;
        }

        newBadgeHistoryArray.push(item);
      });
      this.totalCleanTime = new Date(1000 * totalSeconds).toISOString().substr(11, 8);
    }
    return newBadgeHistoryArray;
  }

  /**
   * Get the formatted QC beacon history to be displayed on 'View Log' button click
   */
  getQCBeaconValues(beaconHistory: IQCBeacon[]) {
    const nameArrays = Object.create(null);
    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    const newBeaconHistoryArray: IQCBeacon[] = [];
    if (beaconHistory.length > 0) {
      beaconHistory.forEach((item, idx, array) => {
        let nameArray = nameArrays[item.Beacon.BeaconName];
        if (nameArray != lastArrayName && lastArrayName != null) {
          timeElapse = this.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntryObject = this.createNewQCObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );
          lastArrayName.push(lastEntryObject);

          newBeaconHistoryArray.push(lastEntryObject);
        }

        if (!nameArray) {
          nameArray = nameArrays[item.Beacon.BeaconName] = [];
          firstintime = item.OptIn;
        }

        nameArray.push(item);
        newBeaconHistoryArray.push(item);

        if (item.OptOut && item.OptOut != 'null') {
          lastouttime = item.OptOut;
        }

        if (array.length - 1 === 0) {
          lastArrayName = nameArray;
          beaconname = item.Beacon.BeaconName;
          batteryLevel = item.BatteryLevel;
        }

        if (idx == array.length - 1) {
          timeElapse = this.utilitiesService.getTimeDiff(firstintime, lastouttime);

          const lastEntry = this.createNewQCObjectProperties(
            beaconname,
            firstintime,
            lastouttime,
            batteryLevel,
            timeElapse,
          );

          lastArrayName.push(lastEntry);

          newBeaconHistoryArray.push(lastEntry);
        }
        lastArrayName = nameArray;
        beaconname = item.Beacon.BeaconName;
        batteryLevel = item.BatteryLevel;
      });
    }
    return newBeaconHistoryArray;
  }

  /**
   * Create object for QC beacons
   */
  createNewQCObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    return {
      Beacon: {
        BeaconName: name,
      },
      OptIn: optin,
      OptOut: optout,
      BatteryLevel: batteryLevel,
      timeElapsed,
    };
  }

  /**
   * Method to open cleaning alert reject modal
   */
  openCleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = true;
  }

  /**
   * Method to close cleaning alert reject modal
   */
  closeCleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = false;
  }

  /**
   * Method to update the cleaning alert
   */
  checkIsQCReject(workorderForm: NgForm, status: any) {
    const rejectStatus = this.workorderStatus.filter((s) => s.StatusId == WoStatus.QC_Rejected);
    if (this.cleaningAlert.WorkorderStatus.StatusId == rejectStatus[0].StatusId) {
      // open modal popup
      this.openCleaningAlertRejectQCModal();
    } else {
      this.updateWorkorder(workorderForm, status);
    }
  }

  /**
   * Method to update the cleaning alert log
   */
  public updateWorkorder(workorderForm: NgForm, status: any) {
    this.loaderService.display(true);

    if (workorderForm.status === 'INVALID' && workorderForm.disabled === false) {
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Cleaning Alert',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    if (this.cleaningAlert.WorkorderStatus.StatusId == null) {
      this.utilitiesService.smmodal('Cleaning Alert', 'Please select Status.');
      this.loaderService.display(false);
      return;
    }

    if (
      (this.cleaningAlert.WorkorderStatus.Status === 'Inprogress' ||
        this.cleaningAlert.WorkorderStatus.Status === 'Assigned') &&
      (this.cleaningAlert.AssignedTo.UserId == null || this.cleaningAlert.AssignedTo.UserId <= 0)
    ) {
      this.utilitiesService.smmodal('Cleaning Alert', 'Please select Assigned To.');
      this.loaderService.display(false);
      return;
    }

    const statusValue = this.workorderStatus.find((s) => s.StatusId == this.cleaningAlert.WorkorderStatus.StatusId);
    if (statusValue.Status === 'Complete') {
      if (this.cleaningAlert.ModifiedOn === null || this.cleaningAlert.ModifiedOn === '') {
        this.utilitiesService.smmodal('Cleaning Alert', 'Please select Completion date.');
        this.loaderService.display(false);
        return;
      }
    }

    this.cleaningAlert.ModifiedBy = this.currentUser.UserId;
    this.cleaningAlert.WorkorderTransaction = {
      Comments: this.cleaningAlert.WorkorderComment,
    };

    if (statusValue.Status === 'Complete') {
      if (this.cleaningAlert.ModifiedOn) {
        this.today = this.utilitiesService.LocaltoVenueTime(this.cleaningAlert.Building.Venue.Timediff);
        const compDate = this.utilitiesService.ConvertDateTimeTZFormat(this.cleaningAlert.ModifiedOn);
        if (compDate) {
          if (compDate < this.utilitiesService.ConvertDateTimeTZFormat(this.cleaningAlert.ScheduleStartDate)) {
            this.utilitiesService.smmodal(
              'Cleaning Alert Log',
              'Completion date should be greater than scheduled start date.',
            );
            this.loaderService.display(false);
            return;
          } else if (compDate > this.today) {
            this.utilitiesService.smmodal(
              'Cleaning Alert Log',
              'Completion date should not be greater than venue current date and time.',
            );
            this.loaderService.display(false);
            return;
          } else {
            this.cleaningAlert.ModifiedOn = null;
          }
        }
      }
    }

    /// below null set because we not update any record of inventory and task when we update work order details
    this.cleaningAlert.workorderTasks = null;
    this.cleaningAlert.WorkorderInventory = null;
    this.cleaningAlert.WorkorderTaskComment = null;

    if (this.reAssignErrorMsg) {
      this.cleaningAlert.IsReassign = 1;
    }

    this.cleaningAlert.GenerateNewWorkOrder = status === 'yes' ? 1 : 0;

    this.WOService.UpdateWorkorder(this.utilitiesService.stripScript(this.cleaningAlert)).subscribe(
      (_data) => {
        this.loaderService.display(false);
        this.closeReassignModal();
        this.closeCleaningAlertRejectQCModal();
        this.utilitiesService.snackBarWithAutoDismiss('Cleaning Alert', _data.Message);
        this.navigateToLogsList();
      },
      (error) => {
        this.loaderService.display(false);
        if (error.status == 406) {
          const msg = error.error;
          this.reAssignErrorMsg = msg.exception;
          this.reAssignModal = true;
        } else {
          console.log(
            'WOService UpdateWorkorder Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        }
      },
    );
  }

  /**
   * Method to open the inspection image
   */
  openInspectionLogImage(imageName: string) {
    this.InspectionImg = imageName;
  }

  /**
   * Method to close the reassign modal
   */
  closeReassignModal() {
    this.reAssignErrorMsg = '';
    this.cleaningAlert.IsReassign = 0;
    this.reAssignModal = false;
  }

  /**
   * Method to be called when user clicks on History button in cleaning alert log.
   */
  getWorkorderHistory(workorderId: number) {
    this.loaderService.display(true);
    const CPworkorder = {
      WorkorderCPId: this.authState.AESEncryptText(workorderId),
    };
    this.WOService.GetWorkorderHistory(CPworkorder).subscribe((data) => {
      this.workorderHistoryDetails = data;
      this.loaderService.display(false);
    });
  }

  /**
   * Method to open the image popup
   */
  openQCAttachment(imageName: string) {
    this.qcAttachmentImg = imageName;
  }

  /**
   * Method to open the image popup
   */
  openCreatorsPhoto(imageName: string) {
    this.CreatorsAttachmentImg = imageName;
  }

  /**
   * Method to view the uploaded video
   */
  downloadVideo(AttachmentName: string) {
    window.open(AttachmentName, '_blank');
  }

  /**
   * Method to be displayed to show the sub menu in inspection history
   */
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  /**
   * Method to navigate from additional cleaning alerts grid and from fulfilled by column
   */
  navigateToAnAlertDetails(id: number) {
    this.isWorkorderDetails = false;
    this.inspectionHistory = false;
    this.router.navigate(['../', id], { relativeTo: this.activatedRoute });
  }
}
