import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { Venue } from 'src/app/core/models/venue.model';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { Module } from '../../../serviceprovider/serviceprovider.module';
import { Pin, WorkorderPriority, WorkorderSeverity } from '../../../workordermanagement/workorder/workorder.module';
import {
  AuthenticationType,
  CMMSIntegrationType,
  CMMSIntegrationTypeKey,
  ClientLogo,
  FeatureConfiguration,
  InspectionScoreFactor,
  RelVenueAuthentication,
  RelVenueCMMSIntegrationTypeUrl,
  RelVenueMapIntegrationTypeUrlModule,
  VenueMapIntegrationTypeKeyModule,
  VenueMapIntegrationTypeModule,
  VenueModule,
  VenueType,
} from '../venue.module';
@Injectable({
  providedIn: 'root',
})
export class VenueService {
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (venue: VenueModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue';
    return this.http.post<ResponseMessage>(_url, venue);
  };

  public Update = (body: VenueModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetVenueList = (body: string, page: number, size: number): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/venue/' + page + '/' + size;
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetVenueDropdown = (body: string, page: number, size: number): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/' + page + '/' + size;
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetSingle = (body: Partial<VenueModule>): Observable<Venue> => {
    const _url: string = this.configuration.Server + 'venue/venue/details';
    return this.http.post<Venue>(_url, body);
  };

  public GetAllTimeZones = (): Observable<any> => {
    const _url = this.configuration.Server + 'venue/timezones';
    return this.http.get<any>(_url);
  };

  // Get all authentication types
  public GetAuthenticationtypeDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<AuthenticationType[]> => {
    const _url: string = this.configuration.Server + 'venue/authentication/types/' + page + '/' + size;
    return this.http.post<AuthenticationType[]>(_url, body);
  };

  // Get authenticationtype keys
  public GetAuthenticationtypekeys = (body: AuthenticationType): Observable<RelVenueAuthentication[]> => {
    const _url: string = this.configuration.Server + 'venue/authentication/types';
    return this.http.post<RelVenueAuthentication[]>(_url, body);
  };

  public GetAllVenuesByUser = (body: string, page: number, size: number, userId: number): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'venue/venues/' + userId + '/' + page + '/' + size;
    return this.http.post<VenueModule[]>(_url, body);
  };

  public VenueFiles(filedata: any[], venueId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'venue/uploadmap';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('venueId', venueId);

      const formData = new FormData();

      for (let i = 0; i < filedata.length; i++) {
        formData.append('file' + i, filedata[i], filedata[i].name);
        // console.log(formData);
      }
      xhr.send(formData);
    });
  }

  public GetAllPins = (): Observable<Pin[]> => {
    const _url: string = this.configuration.Server + 'workorder/pins';
    return this.http.get<Pin[]>(_url);
  };

  public GetAllPriorities = (): Observable<WorkorderPriority[]> => {
    const _url: string = this.configuration.Server + 'workorder/priorities';
    return this.http.get<WorkorderPriority[]>(_url);
  };

  public GetAllSeverities = (): Observable<WorkorderSeverity[]> => {
    const _url: string = this.configuration.Server + 'workorder/severities';
    return this.http.get<WorkorderSeverity[]>(_url);
  };

  // Get all CMMS Integration types
  public GetCMMSIntegrationtypeDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<CMMSIntegrationType[]> => {
    const _url: string = this.configuration.Server + 'venue/cmms/integration/types/' + page + '/' + size;
    return this.http.post<CMMSIntegrationType[]>(_url, body);
  };

  // Get CMMS Integrationtype keys
  public GetCMMSIntegrationTypeKeys = (body: CMMSIntegrationTypeKey): Observable<RelVenueCMMSIntegrationTypeUrl[]> => {
    const _url: string = this.configuration.Server + 'venue/cmms/integration/type/keys';
    return this.http.post<RelVenueCMMSIntegrationTypeUrl[]>(_url, body);
  };

  public GetFeatureConfigurationList = (
    body: string,
    page: number,
    size: number,
  ): Observable<FeatureConfiguration[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/feature/configuration/' + page + '/' + size;
    return this.http.post<FeatureConfiguration[]>(_url, body);
  };

  public GetModueleList = (body: CorporationModule): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'venue/corporation/venue/features';
    return this.http.post<Module[]>(_url, body);
  };

  public GetVenueFeatureConfigurations = (body: Partial<VenueModule>): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'venue/feature/configurations';
    return this.http.post<Module[]>(_url, body);
  };

  public GetInspectionScoreDropDown = (
    body: string,
    page: number,
    size: number,
  ): Observable<InspectionScoreFactor[]> => {
    const _url: string = this.configuration.Server + 'venue/inspection/score/factors/' + page + '/' + size;
    return this.http.post<InspectionScoreFactor[]>(_url, body);
  };

  AddVenueLogo(filedata: File, venueId: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'venue/logo/venue/' + venueId;

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public RemoveVenueLogo = (body: VenueModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/logo/remove';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetClientLogo = (): Observable<ClientLogo> => {
    const _url: string = this.configuration.Server + 'venue/client/logo';
    return this.http.get<ClientLogo>(_url);
  };

  public GetAllVenueTypes = (body: string, page: number, size: number): Observable<VenueType[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/types/' + page + '/' + size;
    return this.http.post<VenueType[]>(_url, body);
  };

  public GetMapIntegrationtypeDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<VenueMapIntegrationTypeModule[]> => {
    const _url: string = this.configuration.Server + 'venue/map/integration/types/' + page + '/' + size;
    return this.http.post<VenueMapIntegrationTypeModule[]>(_url, body);
  };

  public GetMapIntegrationTypeKeys = (
    body: VenueMapIntegrationTypeKeyModule,
  ): Observable<RelVenueMapIntegrationTypeUrlModule[]> => {
    const _url: string = this.configuration.Server + 'venue/map/integration/type/keys';
    return this.http.post<RelVenueMapIntegrationTypeUrlModule[]>(_url, body);
  };

  arcGISMapFile(filedata: File, venueId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'venue/upload/arcgis/map';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('venueId', venueId);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }
}
