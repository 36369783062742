import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { IGetTaskListRequest } from 'src/app/core/models/task.model';
import { ICreateAlertResponse, ICreateCleaningAlertRequest } from 'src/app/core/models/work-order.model';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { LocationSensor } from '../../../corporationmanagement/sensor/sensor.module';
import { FilterInputs } from '../../../inspectionmanagement/inspectorlocationassignment/inspectorlocationassignment.module';
import { Module } from '../../../serviceprovider/serviceprovider.module';
import { VenueTouchlessFeedbackConfig } from '../../../touchlessfeedbackconfiguration/touchless-feedback-config/touchless-feedback-config.module';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { LevelModule } from '../../../venuemanagement/level/level.module';
import { InspectionScoreFactor, VenueModule } from '../../../venuemanagement/venue/venue.module';
import {
  LocationBeacon,
  LocationElementModule,
  LocationGateway,
  LocationGenderType,
  LocationInventoryModule,
  LocationModule,
  LocationTaskModule,
  VenueLocationTouchlessFeedbackReason,
} from '../location.module';
import { IPrintQRRequest } from '../model/location';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetLocationList = (body: string, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/locations/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  public GetLocationDropDown = (body: string, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/locations/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  public GetAllLocationsByVenue = (body: FilterInputs): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/scheduletype/locations';
    return this.http.post<LocationModule[]>(_url, body);
  };

  public GetSingle = (body: LocationModule): Observable<LocationModule> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/details';
    return this.http.post<LocationModule>(_url, body);
  };

  public Add = (body: LocationModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: LocationModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetBuildingDropdown = (body: VenueModule): Observable<BuildingModule[]> => {
    const _url: string = this.configuration.Server + 'venue/buildings';
    return this.http.post<BuildingModule[]>(_url, body);
  };

  public GetLevelDropdown = (body: BuildingModule): Observable<LevelModule[]> => {
    const _url: string = this.configuration.Server + 'venue/building/levels';
    return this.http.post<LevelModule[]>(_url, body);
  };

  public CreateWorkorder = (addAlertRequest: ICreateCleaningAlertRequest): Observable<ICreateAlertResponse> => {
    const _url: string = this.configuration.Server + 'workorder/rest/request';
    return this.http.post<ICreateAlertResponse>(_url, addAlertRequest);
  };

  public CheckCleaningAlerts = (
    venueName: string,
    buildingName: string,
    restRoom: string,
  ): Observable<ResponseMessage> => {
    const toAdd = JSON.stringify({
      buildingName,
      restRoom,
      venueName,
      level: 1,
    });
    const _url: string = this.configuration.Server + 'workorder/check/request';
    return this.http.post<ResponseMessage>(_url, toAdd);
  };

  public GetAllBeaconsByVenue = (body: LocationModule): Observable<LocationBeacon[]> => {
    const _url: string = this.configuration.Server + 'workorder/venue/location/beacons';
    return this.http.post<LocationBeacon[]>(_url, body);
  };

  public GetAllSensorsByVenue = (body: LocationModule): Observable<LocationSensor[]> => {
    const _url: string = this.configuration.Server + 'corporation/location/venue/sensors';
    return this.http.post<LocationSensor[]>(_url, body);
  };

  public GetLocationsByBuilding = (body: LocationModule, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/building/locations/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  public VenueFiles(filedata: any[], venueId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'venue/upload/location/map';
      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('locationId', venueId);

      const formData = new FormData();
      for (let i = 0; i < filedata.length; i++) {
        formData.append('file' + i, filedata[i], filedata[i].name);
      }
      xhr.send(formData);
    });
  }

  SvgFile(filedata: File, locationId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'venue/upload/location/svg/map';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('locationId', locationId);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public GetLocationTypes = (body: string, page: number, size: number): Observable<LocationGenderType[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/locationtypes/' + page + '/' + size;
    return this.http.post<LocationGenderType[]>(_url, body);
  };

  public RemoveSVG = (body: LocationModule): Observable<LocationModule> => {
    const headers = new HttpHeaders({
      Authorization: 'bearer ' + this.configuration.Token,
    });
    const _url: string = this.configuration.Server + 'venue/remove/location/svg';
    return this.http.post<LocationModule>(_url, body, { headers });
  };

  public GetAllGatewaysByVenue = (body: LocationModule): Observable<LocationGateway[]> => {
    const _url: string = this.configuration.Server + 'badge/venue/location/gateways';
    return this.http.post<LocationGateway[]>(_url, body);
  };

  public GetAllTasksByVenue = (body: IGetTaskListRequest): Observable<LocationTaskModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/venue/tasks';
    return this.http.post<LocationTaskModule[]>(_url, body);
  };

  /**
   * Method to get the list of tasks for task selection
   */
  public GetAllLocationTasks = (body: IGetTaskListRequest): Observable<LocationTaskModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/active/tasks';
    return this.http.post<LocationTaskModule[]>(_url, body);
  };

  public GetAllInventoriesByVenue = (body: LocationModule): Observable<LocationInventoryModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/venue/inventories';
    return this.http.post<LocationInventoryModule[]>(_url, body);
  };

  public GetAllElementsByVenue = (body: LocationModule): Observable<LocationElementModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/venue/elements';
    return this.http.post<LocationElementModule[]>(_url, body);
  };

  public GetFeatureConfigurations = (body: LocationModule): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'venue/location/venue/feature/configurations';
    return this.http.post<Module[]>(_url, body);
  };

  public GetAllFeedbackReasonsByVenue = (body: LocationModule): Observable<VenueLocationTouchlessFeedbackReason[]> => {
    const _url: string = this.configuration.Server + 'touchless/feedback/venue/location/reasons';
    return this.http.post<VenueLocationTouchlessFeedbackReason[]>(_url, body);
  };

  public GetAllFeedbackConfigurationIdentifiers = (body: any): Observable<VenueTouchlessFeedbackConfig[]> => {
    const _url: string = this.configuration.Server + 'touchless/feedback/configuration/identifiers';
    return this.http.post<VenueTouchlessFeedbackConfig[]>(_url, body);
  };

  public DownloadLocationDetails(): Observable<any> {
    const url: string = this.configuration.Server + 'venue/export/location/information';
    return new Observable((obs) => {
      const oReq = new XMLHttpRequest();
      oReq.open('GET', url, true);
      oReq.setRequestHeader('content-type', 'application/json');
      oReq.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      oReq.responseType = 'arraybuffer';

      oReq.onload = function () {
        const arrayBuffer = oReq.response;
        const byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };
      oReq.send();
    });
  }

  public GetAllScoreFactorsByVenue = (body: any): Observable<InspectionScoreFactor[]> => {
    const _url: string = this.configuration.Server + 'venue/location/inspection/score/factors';
    return this.http.post<InspectionScoreFactor[]>(_url, body);
  };

  /**
   * Method to generate the QR Code Pdf
   */
  generateQRCodePdf(request: IPrintQRRequest): Observable<any> {
    const _url: string = this.configuration.Server + 'venue/download/location/qr-code';
    return this.http.post(_url, request, { responseType: 'blob' });
  }
}
