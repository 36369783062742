import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IGetTaskListRequest, ITaskParams, ITasks } from 'src/app/core/models/task.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { LocationService } from 'src/app/features/staffscheduling/location/components/location.http.service';
import { LoaderService } from 'src/app/shared/directives/loader.service';

@Component({
  selector: 'app-task-selection',
  templateUrl: './task-selection.component.html',
  styleUrls: ['./task-selection.component.scss'],
})
export class TaskSelectionComponent implements OnChanges {
  @Input() taskParams: ITaskParams;
  @Input() selectAllAction = true;
  @Input() tasksList: ITasks[];
  @Output() selectedTasks = new EventEmitter<ITasks[]>();

  tasks: ITasks[] = [];
  isSelectAll = false;

  constructor(
    private readonly locationService: LocationService,
    private readonly authState: AuthState,
    private loaderService: LoaderService,
  ) {}

  ngOnChanges(): void {
    if (!this.tasksList) {
      this.loaderService.display(true);
      const request: IGetTaskListRequest = {
        LocationCPId: this.authState.AESEncryptText(this.taskParams.locationId),
        Venue: {
          VenueCPId: this.authState.AESEncryptText(this.taskParams.venueId),
        },
        VenueLocationTypeId: this.taskParams.venueLocationTypeId,
      };
      this.locationService.GetAllLocationTasks(request).subscribe((tasks) => {
        this.tasks = tasks;
        this.toggleSelectAll(false);
        this.loaderService.display(false);
      });
    } else {
      this.tasks = this.tasksList;
      this.toggleSelectAll(false);
    }
  }

  /**
   * Method to be called on select all/unselect all
   */
  toggleSelectAll(checked: boolean) {
    this.tasks.forEach((x) => (x.IsActive = checked));
    this.selectedTasks.emit(this.tasks);
  }

  /**
   * Method to be called on checkbox selection or unselection
   */
  toggle() {
    this.isSelectAll = this.tasks.every((x) => !!x.IsActive);
    this.selectedTasks.emit(this.tasks);
  }
}
