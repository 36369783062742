import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import versions from 'package.json';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { Login, UserTokenAuthentication } from 'src/app/core/models/common.model';
import { User } from 'src/app/core/models/user.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private headers: HttpHeaders;
  private loginHeader: HttpHeaders;
  private loginHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authState: AuthState,
    private configuration: Configuration,
  ) {
    this.loginDetails.bind(this);
    this.headers = new HttpHeaders();
    this.loginHeader = new HttpHeaders();
    this.loginHeaders = new HttpHeaders();

    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Authorization', 'bearer ' + this.configuration.Token);

    this.loginHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    this.loginHeaders.append('Accept', 'application/json');

    this.loginHeader.append('Content-Type', 'application/json');
    this.loginHeader.append('Accept', 'application/json');
  }

  public login = (user: User): Observable<User> => {
    const password = this.authState.AESEncryptText(user.Password);
    const payload = 'username=' + user.Email + '&password=' + encodeURIComponent(password) + '&grant_type=password';

    const _url: string = this.configuration.Server + 'oauth/login';
    return this.http
      .post(_url, payload, { headers: this.loginHeaders })
      .pipe(map((response: any) => this.setLoginDetails(response.user, response.access_token)));
  };

  public loginDetails = (user: Login): Observable<User> => {
    const _url: string = this.configuration.Server + 'open/claims/web/login';
    return this.http
      .post(_url, { version: versions.version, ...user }, { headers: this.loginHeader })
      .pipe(map((response: any) => this.setLoginDetails(response.user, response.access_token)));
  };

  /**
   * Method to set login details and store values
   */
  setLoginDetails(user, token) {
    this.configuration.Token = token;
    this.configuration.isUnauthorizedState = false;
    const _user = JSON.parse(user);

    this.authState.clearTraxToken();
    this.authState.clearTraxCurrentUser();
    this.authState.clearUserVenue();
    this.authState.clearUserZone();

    /// after successful login store user object & token in local storage
    this.authState.storedTraxCurrentUser(_user);

    this.authState.storedTraxToken(token);
    if (_user.scheduleDetails?.length > 0) {
      this.authState.storeUserVenueId(_user.scheduleDetails[0].zone.building.venue.venueId);
      this.authState.storeUserZoneId(_user.scheduleDetails[0].zone.ZoneId);
    } else {
      this.authState.storeUserVenueId(0);
      this.authState.storeUserZoneId(0);
    }

    if (
      _user.relUserVenues?.length > 0 &&
      _user.relUserVenues[0].Venue &&
      _user.relUserVenues[0].Venue.UserAssignmentRule != null
    ) {
      const venueDetail = _user.relUserVenues[0].Venue;
      const locationUserAssignment = venueDetail.Venue.UserAssignmentRule.LocationUserAssignment;
      this.authState.storedShiftStaffSchedule(venueDetail.Venue.UserAssignmentRule.ShiftStaffSchedule);
      this.authState.storedLocationUserAssignment(locationUserAssignment);
      this.authState.storedEitherOneOrThese(venueDetail.Venue.UserAssignmentRule.EitherOneOrThese);

      this.configuration.isShiftStaffSchedule = venueDetail.Venue.UserAssignmentRule.ShiftStaffSchedule == 1 ? 1 : 0;
      this.configuration.isLocationUserAssignment = locationUserAssignment == 1 ? 1 : 0;
      this.configuration.isEitherOneOrThese = locationUserAssignment == 1 ? 1 : 0;
    }
    return _user;
  }

  public SignUpUser = (user: User): Observable<any> => {
    const _url: string = this.configuration.Server + 'open/claims/signup/user';
    return this.http.post(_url, user, { headers: this.headers });
  };

  UserImage(filedata: File, id: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'open/claims/user/profile/' + id;
      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public ForgotPassword = (user: User): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'open/claims/forgotpassword';
    return this.http.post<ResponseMessage>(_url, user, { headers: this.headers });
  };

  public InsertUserTokenAuthentication = (payload: Partial<UserTokenAuthentication>): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/token/venue';
    return this.http.post<ResponseMessage>(_url, payload);
  };

  public UserLogout = (user: Login): Observable<any> => {
    const _url: string = this.configuration.Server + 'admin/web/logout';
    return this.http.post(_url, { version: versions.version, ...user }, { headers: this.headers });
  };

  public ApiVersion = (): Observable<string> => {
    const _url: string = this.configuration.Server + 'open/claims/version';
    return this.http.get<string>(_url, { headers: this.headers });
  };
}
