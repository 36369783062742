import { Component, Input, OnChanges } from '@angular/core';
import { CleaningAlertService } from 'src/app/shared/services/cleaning-alerts.service';

// This component displays the open cleaning alert count
// In Schedule creation/cleaning alert creation/ followup alert creations

@Component({
  selector: 'app-open-cleaning-alert-count',
  templateUrl: './open-cleaning-alert-count.component.html',
  styleUrls: ['./open-cleaning-alert-count.component.scss'],
})
export class OpenCleaningAlertCountComponent implements OnChanges {
  /**
   * Location Identifier
   */
  @Input() locationId: number;

  openCleaningAlerts: number = null;
  constructor(private readonly cleaningAlertService: CleaningAlertService) {}

  ngOnChanges(): void {
    this.cleaningAlertService.getOpenCleaningAlerts(this.locationId).subscribe((count) => {
      this.openCleaningAlerts = count;
    });
  }
}
