import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'trax-header-search',
  templateUrl: './trax-header-search.component.html',
  styleUrls: ['./trax-header-search.component.scss'],
})
export class TraxHeaderSearchComponent {
  public searchString = '';

  @Output() search = new EventEmitter<string>();
  @Output() textChanged = new EventEmitter<string>();

  public clearSearch(): void {
    this.searchString = '';
    this.search.emit(this.searchString);
  }

  public doSearch(searchString: string): void {
    this.search.emit(searchString);
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.doSearch(this.searchString);
      return;
    }
    // Emit the current search string on every keyup
    this.textChanged.emit(this.searchString);
  }
}
